/**
 * Return human friendly protocol name
 * @param {string} protocol
 * @returns string
 */
export const humanizedProtocol = (protocol = "") => {
	if (protocol.toLowerCase().search("lora") !== -1) {
		return "LoRaWAN";
	} else {
		return protocol.replace("_", " ");
	}
};
export const Protocols = Object.freeze({
	BACNET_IP: { label: "BACnet IP", value: "BACNET_IP" },
	BACNET_MSTP: { label: "BACnet MSTP", value: "BACNET_MSTP" },
	KNX: { label: "KNX", value: "KNX" },
	LORAWAN_V1_0: { label: "LoRaWAN", value: "LORAWAN_V1_0" },
	LON_FT10: { label: "LON FT 10", value: "LON_FT10" },
	LON_IP852: { label: "LON IP-852", value: "LON_IP852" },
	LPB: { label: "LPB", value: "LPB" },
	MBUS: { label: "M-BUS", value: "MBUS" },
	MODBUS_IP: { label: "Modbus TCP/IP", value: "MODBUS_IP" },
	MODBUS_SERIAL: { label: "Modbus RTU", value: "MODBUS_SERIAL" },
	MQTT: { label: "MQTT", value: "MQTT" },
	// DEPRECATED Protocols
	WMBUS: { value: "WMBUS", label: "WM-BUS" },
	PLUGIN: { label: "Plugin", value: "PLUGIN" },
	KNX_S: { label: "KNX S", value: "KNX_S" },
	KNX_LTE: { label: "KNX LTE", value: "KNX_LTE" }
});

export const GatewayProtocols = Object.freeze({
	BACNET_IP_GATEWAY: {
		label: "BACnet IP Gateway",
		value: "BACNET_IP_GATEWAY"
	},
	MODBUS_IP_GATEWAY: {
		label: "Modbus TCP/IP Gateway",
		value: "MODBUS_IP_GATEWAY"
	},
	MQTT_GATEWAY: { label: "MQTT Gateway", value: "MQTT_GATEWAY" },
	PLUGIN_GATEWAY: { label: "Plugin", value: "PLUGIN_GATEWAY" }
});
