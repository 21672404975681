<template>
	<div
		class="field"
		:class="[{ 'is-flex is-justify-content-space-between': isInline }]"
		v-bind="$attrs"
		:data-testid="dataTestid"
	>
		<ws-form-label
			v-if="label || $slots.label"
			:disabled="disabled"
			:id="id"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltipContent"
		>
			<slot name="label">{{ label }}</slot>
		</ws-form-label>
		<label class="label-description" v-if="labelDescription">
			{{ labelDescription }}
		</label>
		<slot name="sectionUnderLabel" />
		<div
			class="control is-expanded inputField"
			:class="{ 'has-text-danger': !!error }"
		>
			<vue-multiselect
				ref="vSelect"
				v-if="searchable"
				track-by="value"
				label="label"
				:aria-labelledby="id"
				:model-value="content"
				:placeholder="placeholder"
				:options="availableOptions"
				:open-direction="openDirection"
				:searchable="searchable"
				:close-on-select="closeOnSelect"
				:multiple="false"
				:loading="loading"
				:allow-empty="allowNull"
				:preserve-search="true"
				:disabled="disabled"
				:internal-search="false"
				:option-height="
					optionHeight + 5
					/* + 5 to make sure when using keyboard to select, it will scroll properly */
				"
				:deselect-label="
					!showMultiselectLabels || !allowNull
						? ''
						: $t('press-enter-to-deselect')
				"
				:select-label="
					!showMultiselectLabels ? '' : $t('press-enter-to-select')
				"
				:selected-label="showMultiselectLabels ? '' : $t('selected')"
				:group-values="isGroupable ? groupValues : null"
				:group-label="isGroupable ? groupLabel : null"
				:group-select="isGroupable ? false : null"
				@update:model-value="handleChange"
				@remove="handleRemove"
				@open="handleOpen"
				@close="handleClose"
				@search-change="handleSearch"
			>
				<template #singleLabel="props">
					<slot name="customSingleLabel" :option="props.option">
						<label :id="id" :value="props.option.value">
							{{ props.option.label }}
						</label>
					</slot>
				</template>

				<template #option="props">
					<slot name="customOption" :option="props.option">
						{{ props.option.label || props.option.$groupLabel }}
					</slot>
				</template>
				<template #caret>
					<ws-icon
						icon="angle-right"
						class="multiselect__select"
					></ws-icon>
				</template>
				<template #noResult> {{ $t("no-results") }} </template>
			</vue-multiselect>

			<div
				class="select is-fullwidth"
				v-if="!searchable"
				:class="{ disabled: disabled, 'is-danger': !!error }"
				:style="{ width: '100%' }"
			>
				<select
					class="select"
					v-model="content"
					@change="handleChange($event)"
					:disabled="disabled"
					:placeholder="placeholder"
					:aria-labelledby="id"
					:class="{
						'select--is-empty': !!content && !(content + '').length
					}"
					:data-testid="`${dataTestid}-select`"
				>
					<option
						value=""
						v-if="allowNull || placeholder"
						selected
						:disabled="!allowNull"
					>
						{{ nullableLabel || placeholder }}
					</option>
					<option
						v-for="(opt, index) in options"
						:key="index"
						:value="opt.value"
					>
						{{ opt.label }}
					</option>
				</select>
			</div>
			<slot name="afterInput" />

			<p
				class="help mb-0"
				v-if="labelUnderInput || $slots.labelUnderInput"
			>
				<span v-if="labelUnderInput" v-html="labelUnderInput" />
				<slot name="labelUnderInput" />
			</p>
			<p class="help is-danger" v-if="!!error">
				{{ error }}
			</p>
		</div>
	</div>
</template>

<script>
import EventBus from "@/eventbus";
import { generateRandomId } from "@/helpers/functions.helper.js";
import VueMultiselect from "vue-multiselect";

export default {
	name: "WsFormSelect",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: String,
			default: null
		},
		labelDescription: {
			type: String,
			default: null
		},
		optional: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: [String, Number, Boolean],
			default: null
		},
		placeholder: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => []
		},
		openDirection: {
			type: String,
			default: "auto"
		},
		allowNull: {
			type: Boolean,
			default: true
		},
		nullableLabel: {
			type: String,
			default: null
		},
		searchable: {
			type: Boolean,
			default: false
		},
		closeOnSelect: {
			type: Boolean,
			default: true
		},

		loading: {
			type: Boolean,
			default: false
		},

		tooltip: {
			type: [String, Object],
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean],
			default: false
		},
		addSearchValue: {
			type: Boolean,
			default: true
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		optionHeight: {
			type: Number,
			default: 50
		},
		showMultiselectLabels: {
			type: Boolean,
			default: false
		},
		isGroupable: {
			type: Boolean,
			default: false
		},
		groupValues: {
			type: String,
			default: "options"
		},
		groupLabel: {
			type: String,
			default: "group"
		},
		isInline: {
			type: Boolean,
			default: false
		},
		dataTestid: {
			type: String,
			default: "form-selector"
		}
	},
	emits: ["update:modelValue", "mounted", "change", "open", "close"],

	data() {
		return {
			content: this.modelValue,
			newOptions: this.options,
			searchTerm: null
		};
	},

	created() {
		this.updateOptions();
	},

	mounted() {
		const _selected = this.getSelectedObjectFromValue(this.modelValue);
		this.content =
			_selected !== null
				? this.getSelectedContentFromAvailableOptions()
				: "";
		this.$emit("mounted");
	},

	watch: {
		options: {
			handler: function (_new) {
				this.updateOptions(_new);
				this.content = this.getSelectedContentFromAvailableOptions();
			},
			deep: true
		},

		modelValue: function () {
			this.content = this.getSelectedContentFromAvailableOptions();
		}
	},

	methods: {
		handleChange(selected) {
			let final;
			if (this.searchable) {
				final = selected?.value ?? null;
			} else {
				final = selected?.target?.value ?? null;
			}

			// if it's a new value user typed, add it to the options list
			if (this.addSearchValue) {
				this.addValueToAvailableOptions(final);
			}

			this.$emit("update:modelValue", final);
			this.$emit("change", final);
		},

		handleRemove() {
			this.$emit("update:modelValue", null);
			this.$emit("change", null);
		},

		handleSearch(term) {
			this.searchTerm = term;
		},

		handleOpen() {
			EventBus.$emit("ws-form-select-opened", true);
			this.$emit("open");
			if (!this.content) {
				return;
			}
		},

		handleClose() {
			EventBus.$emit("ws-form-select-opened", false);
			this.$emit("close");
		},

		/**
		 * Add value to the available list that user typed on search input
		 * @param {string} value Custom value user typed
		 */
		addValueToAvailableOptions(value) {
			if (value !== null && !this.isValueInAvailableOptions(value)) {
				const newOptions = [{ label: value, value }, ...this.options];
				this.updateOptions(newOptions);
			}
		},

		/**
		 * Get the object from objects using a value to search
		 * @param  {string|number} value
		 * @return {object}
		 */
		getSelectedObjectFromValue(value) {
			if (this.isGroupable) {
				for (const group of this.newOptions) {
					const selectedValue = group[this.groupValues].find(
						(option) => option.value == value
					);
					if (selectedValue) {
						return selectedValue;
					}
				}
				return null;
			}

			const index = this.newOptions.findIndex(
				(opt) => opt.value == value
			);
			return index !== -1 ? this.newOptions[index] : null;
		},

		/**
		 * Check if value is available in options
		 * @param  {string|number}  value
		 * @return {Boolean}
		 */
		isValueInAvailableOptions(value) {
			if (value === undefined || value === null || value === "") {
				return true;
			}

			const listValues = this.newOptions.reduce((acc, opt) => {
				if (this.isGroupable) {
					return [
						...acc,
						...opt[this.groupValues].map((option) => option.value)
					];
				}
				return [...acc, opt.value];
			}, []);
			return listValues.find((opt) => opt === value) !== undefined;
		},

		/**
		 * Update available options
		 * @param  {array - optional} _new Array with new options
		 * @return {array}
		 */
		updateOptions(_new) {
			this.newOptions = [...(_new || this.options)];

			if (!this.isGroupable) {
				if (
					!this.isValueInAvailableOptions(this.modelValue) &&
					this.addSearchValue
				) {
					this.newOptions = [
						{
							label:
								(this.content ? this.content.label : null) ||
								this.modelValue,
							value: this.modelValue
						},
						...this.newOptions
					];
				}
			}

			return this.newOptions;
		},

		getSelectedContentFromAvailableOptions() {
			let final = this.modelValue;

			if (this.searchable) {
				if (this.isGroupable) {
					final = this.availableOptions.reduce((acc, group) => {
						if (acc) {
							return acc;
						}
						const selectedItem = group[this.groupValues].find(
							(option) =>
								option.value == this.modelValue ||
								option.label == this.modelValue
						);
						if (selectedItem) {
							return selectedItem;
						}
						return null;
					}, null);
				} else {
					final = this.availableOptions.find(
						(option) =>
							option.value == this.modelValue ||
							option.label == this.modelValue
					);
				}
			}

			if (this.searchable && final) {
				return final;
			}

			if (!final?.value && !final && final !== false && final !== 0) {
				return "";
			}

			return final?.value || final;
		},

		openSelect() {
			const vSelect = this.$refs.vSelect;
			if (vSelect) {
				vSelect.activate();
			}
		}
	},

	computed: {
		tooltipContent() {
			if (this.tooltip && typeof this.tooltip === "string") {
				return {
					content: this.tooltip,
					placement: this.tooltipPosition,
					html: true
				};
			}

			return this.tooltip;
		},

		/**
		 * Get available options (if search term is not available, add it)
		 * @return {array}
		 */
		availableOptions() {
			function formatLabel(label) {
				return (label || "")
					.toString()
					.toLowerCase()
					.replace(/[\W_]/gim, " ")
					.replace(/\s+/g, " "); //multiple spaces into one
			}
			let tempNewOptions = this.newOptions;
			const term = this.searchTerm;
			if (!this.searchable || !term) {
				// add the selected value, non-available in the options to the list
				if (this.content && !this.isGroupable && this.addSearchValue) {
					const contentValueAlreadyAdded = tempNewOptions.find(
						(opt) => opt.value === this.content.value
					);
					if (!contentValueAlreadyAdded) {
						tempNewOptions = [this.content, ...tempNewOptions];
					}
				}
				return tempNewOptions;
			}

			const searchableTerm = formatLabel(term);
			if (this.isGroupable) {
				// if it's groupable, it should filter out the options non-matche with search term inside of options (groupable options: [{group: string, *options: array}] | *options: [{label: string, value: string}])
				tempNewOptions = tempNewOptions
					.map((group) => {
						const _options = group[this.groupValues].filter(
							(opt) => {
								const _label = formatLabel(opt?.label || "");
								return _label.includes(searchableTerm);
							}
						);
						if (_options.length === 0) {
							return null;
						}
						return {
							...group,
							[this.groupValues]: _options
						};
					})
					.filter((group) => group); // remove nullable options
			} else {
				// non-groupable options are simple as an array: [{label: string, value: string}]
				tempNewOptions = tempNewOptions.filter((option) => {
					const _label = formatLabel(option?.label || "");
					return _label.includes(searchableTerm);
				});
			}

			if (
				tempNewOptions.length === 0 &&
				this.addSearchValue &&
				!this.isGroupable
			) {
				tempNewOptions = [
					{
						label: term,
						value: term
					},
					...tempNewOptions
				];
			}

			return tempNewOptions;
		}
	},

	components: {
		VueMultiselect
	}
};
</script>

<style lang="scss">
.multiselect {
	&.multiselect--active {
		z-index: 10;
	}

	// to the selected option, this will truncate longer texts and add a "..." to the end of it
	.multiselect__single {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.has-text-danger {
	.multiselect {
		.multiselect__tags {
			border-color: $color-danger-500;
		}
	}
}
</style>

<style lang="scss" scoped>
.select {
	border-radius: 6px;

	&--is-empty {
		color: $color-grey-500;

		option:not(:disabled) {
			color: $color-grey-700;
		}
	}

	&:disabled {
		border: 1px solid $color-grey-400;
		background-color: $color-grey-200;
		color: $color-grey-500;
		opacity: 1;
	}
}
.multiselect__content-wrapper {
	min-width: 440px;
}
.label-description {
	display: block;
	font-size: $size-7;
	font-weight: $weight-normal;
	color: $color-grey-500;
	margin-top: -0.5rem;
	margin-bottom: 0.2rem;
}
.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
}
.optional {
	font-weight: 400;
	font-size: smaller;
	font-style: italic;
}
.help:not(.is-danger) {
	color: $grey;
}
.help.is-danger {
	line-height: 1rem;
}
</style>
