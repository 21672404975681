<template>
	<label
		class="label"
		:class="{
			disabled: !!props.disabled,
			'has-text-danger': !!props.error,
			inlineLabel: props.isInline
		}"
		:id="props.id"
	>
		{{ props.text }}
		<span v-if="props.optional" class="optional">
			({{ $t("optional") }})
		</span>
		<span
			class="icon ml-1"
			v-tooltip="{
				content: props.tooltip,
				placement: props.tooltipPosition
			}"
			v-if="props.tooltip !== null"
		>
			<ws-icon size="sm" icon="info" />
		</span>
	</label>
</template>
<script setup>
import { generateRandomId } from "@/helpers/functions.helper.js";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();

const props = defineProps({
	id: {
		type: String,
		default: () => {
			return generateRandomId();
		}
	},
	text: {
		type: [String, null],
		default: ""
	},
	optional: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	},
	error: {
		type: [String, Boolean, null],
		default: false
	},
	isInline: {
		type: Boolean,
		default: false
	},
	tooltip: {
		type: String,
		default: null
	},
	tooltipPosition: {
		type: String,
		default: "top"
	}
});
</script>

<style lang="scss" scoped>
.label {
	text-align: left;
}

.optional {
	font-weight: 400;
	font-size: smaller;
	font-style: italic;
}

.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
</style>
