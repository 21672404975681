import { useObjectImport } from "./useObjectImport.js";
import { computed } from "vue";

/**
 *
 * @param {Ref: { objectType: string }} options
 * @returns
 */
export function useWsDropzone(options) {
	const identifier = computed(() => options.value?.objectType);
	const { config } = useObjectImport(identifier);

	return {
		requiredHeaders: computed(() => config.value.requiredHeaders),
		allowedHeaders: computed(() => config.value.allowedHeaders),
		// dont destructure formatToPreview to get function updates
		config
	};
}
