<template>
	<button
		ref="button"
		class="button"
		:class="{
			'is-primary': isPrimary,
			'is-success': isSuccess,
			'is-danger': isDanger,
			'is-info': isInfo,
			'is-warning': isWarning,
			'is-grey': isGrey,
			'is-light': isLight,
			'is-outlined': isOutlined,
			'is-fullwidth': isFullwidth,
			'no-background': isSubtle || isTransparentBackground,
			'no-border': isSubtle || isNoBorder,
			'is-rounded': isRounded,
			'is-loading': isLoading,
			'is-delete': isDelete,
			'is-only-icon': hasOnlyIcon,
			'is-small': isSmall || size === 'small',
			'is-medium': isMedium || size === 'medium',
			'is-large': isLarge || size === 'large',

			'has-text-weight-light': fontWeight === 'light',
			'has-text-weight-normal': fontWeight === 'normal',
			'has-text-weight-medium': fontWeight === 'medium',
			'has-text-weight-semibold': fontWeight === 'semibold',
			'has-text-weight-bold': fontWeight === 'bold'
		}"
		:type="type"
		:disabled="disabled"
		v-tooltip="tooltip"
		:title="title"
		@click="$emit('click', $event)"
	>
		<span
			class="icon"
			:class="{
				'is-small': hasIconSmallSlot || isSmall,
				'is-medium': isMedium
			}"
			v-if="hasIconSlot && iconPosition === 'left'"
		>
			<slot name="icon" />
			<slot name="iconSmall" />
		</span>

		<template v-if="$slots.default">
			<span v-if="hasIconSlot">
				<slot />
			</span>
			<slot v-else />
		</template>

		<span
			class="icon icon-right"
			:class="{
				'is-small': hasIconSmallSlot || isSmall,
				'is-medium': isMedium
			}"
			v-if="hasIconSlot && iconPosition === 'right'"
		>
			<slot name="icon" />
			<slot name="iconSmall" />
		</span>
	</button>
</template>

<script>
import { Comment } from "vue";
const defaultFalsePropDefinition = {
	type: Boolean,
	default: false
};
const defaultTruePropDefinition = {
	type: Boolean,
	default: true
};
export default {
	name: "WsButton",

	emits: {
		// No validation
		click: null
	},

	props: {
		// button HTML type Attribute
		type: {
			type: String,
			default: "button",
			validation: function (value) {
				return ["button", "submit", "reset"].includes(value);
			}
		},

		disabled: defaultFalsePropDefinition,
		// colors
		isPrimary: defaultFalsePropDefinition,
		isSuccess: defaultFalsePropDefinition,
		isDanger: defaultFalsePropDefinition,
		isInfo: defaultFalsePropDefinition,
		isWarning: defaultFalsePropDefinition,
		isGrey: defaultFalsePropDefinition,

		isLight: defaultFalsePropDefinition,

		isTransparentBackground: defaultFalsePropDefinition,
		isSubtle: defaultFalsePropDefinition,
		isNoBorder: defaultFalsePropDefinition,

		// legacy sizes
		isNormal: defaultTruePropDefinition,
		isSmall: defaultFalsePropDefinition,
		isMedium: defaultFalsePropDefinition,
		isLarge: defaultFalsePropDefinition,

		size: {
			type: String,
			default: "normal",
			validation: function (value) {
				return ["normal", "small", "medium", "large"].includes(value);
			}
		},
		iconPosition: {
			type: String,
			default: "left",
			validation: function (value) {
				return ["right", "left"].includes(value);
			}
		},
		fontWeight: {
			type: String,
			default: "semibold",
			validation: function (value) {
				return [
					// "thin", // 100
					// "extralight", // 200
					"light", // 300
					"normal", // 400
					"medium", // 500
					"semibold", // 600
					"bold" // 700
					// "extrabold", // 800
					// "heavy", // 900
				].includes(value);
			}
		},

		// styling
		isOutlined: defaultFalsePropDefinition,
		isFullwidth: defaultFalsePropDefinition,
		isRounded: defaultFalsePropDefinition,

		isLoading: defaultFalsePropDefinition,
		isDelete: defaultFalsePropDefinition,

		tooltip: { type: String, default: null },
		title: { type: String, default: null }
	},

	watch: {
		isLoading: function (value) {
			// remove the :hover/:active status of the button that makes the loading icon becomes transparent when button is-outlined
			if (value) {
				this.$refs?.button?.blur();
			}
		}
	},

	computed: {
		hasIconSlot() {
			return !!(this.$slots?.icon || this.$slots?.iconSmall);
		},

		hasIconSmallSlot() {
			return !!this.$slots?.iconSmall;
		},

		hasOnlyIcon() {
			// code inspired by this article: https://mokkapps.de/vue-tips/check-if-slot-is-empty
			// the trick here is the "slot.type !== Comment"
			let isEmpty = true;
			try {
				const defaultSlot = this.$slots.default;
				if (defaultSlot) {
					const slots = defaultSlot();
					const slotsAsArray = Array.isArray(slots)
						? slots
						: [slots].filter(Boolean); // in case slots === null|undefined, filter it out
					for (const slot of slotsAsArray) {
						if (slot.type !== Comment) {
							isEmpty = false;
						}
					}
				}

				return isEmpty;
			} catch {
				return false;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
$defaultSpacing: 0.5rem;
$borderSize: 1px;
$focusOutlineColor: $color-info-500;

$normalButtonSize: 2.5rem;
$normalIconSize: 24px;
$normalIconFontSize: 24px;

$smallButtonSize: 2rem;
$smallIconSize: 21px;
$smallIconFontSize: 21px;

button,
.button {
	border-radius: $button-radius !important;
	transition: all 0.1s linear;
	font-weight: 600;
	display: flex;
	align-items: center;
	outline: none;

	padding: calc($defaultSpacing - $borderSize);
	box-shadow: none;

	cursor: pointer;

	.title {
		font-size: 14px;
	}
	.text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.icon {
		width: $normalIconSize;
		min-width: $normalIconSize;
		height: $normalIconSize;
		min-height: $normalIconSize;
	}

	:deep(svg) {
		width: $normalIconFontSize;
		height: $normalIconFontSize;
		max-height: 100%;
	}
	:deep(.ws-icon em) {
		font-size: $normalIconFontSize;
	}

	&:is-hovered,
	&:hover {
		outline: none;
	}

	&.is-focus-visible,
	&:focus-visible {
		outline-offset: 3px;
		outline: 2px auto $focusOutlineColor;
	}

	&.is-only-icon {
		width: $normalButtonSize;
		height: $normalButtonSize;

		.icon:first-child:last-child,
		.icon:first-child:not(:last-child),
		.icon:not(:first-child):last-child,
		.icon {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	.icon:first-child:not(:last-child) {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.icon:not(:first-child):last-child {
		margin-left: 0.5rem !important;
		margin-right: 0 !important;
	}

	.icon.is-small {
		font-size: $smallIconFontSize;

		width: $smallIconSize;
		min-width: $smallIconSize;
		height: $smallIconSize;
		min-height: $smallIconSize;

		:deep(svg) {
			width: $smallIconFontSize;
			height: $smallIconFontSize;
			max-height: 100%;
		}
		:deep(.ws-icon em) {
			font-size: $smallIconFontSize;
			width: $smallIconFontSize;
			height: $smallIconFontSize;
		}
	}
}

.button.is-large,
button.is-large {
	font-size: 1.25rem !important;

	:deep(.ws-icon em) {
		font-size: $normalIconSize;
	}
}
.button.is-small,
button.is-small {
	border-radius: $button-radius !important;

	.icon {
		width: $smallIconSize;
		min-width: $smallIconSize;
		height: $smallIconSize;
		min-height: $smallIconSize;
	}
	:deep(svg) {
		width: $smallIconFontSize;
		height: $smallIconFontSize;
		max-height: 100%;
	}
	:deep(.ws-icon em) {
		font-size: $smallIconFontSize;
	}
	&.is-only-icon {
		min-width: $smallButtonSize;
		width: $smallButtonSize;
		max-width: $smallButtonSize;
		min-height: $smallButtonSize;
		height: $smallButtonSize;
		max-height: $smallButtonSize;
		padding: 0;
	}
}

.button.is-rounded,
button.is-rounded {
	border-radius: 9999px !important;
}

.button.no-background,
button.no-background {
	background-color: transparent;
	border-color: transparent;

	&.is-selected,
	&:hover,
	&.is-hovered {
		background-color: $color-grey-200;
	}
}

.button.no-border,
button.no-border {
	border: none;
}

.button.is-loading,
button.is-loading {
	input {
		display: none;
	}
}

// colors
.button,
button {
	border-color: $color-grey-300;
	color: $color-grey-700;

	&.is-selected,
	&.is-hovered,
	&:hover {
		border-color: $color-grey-500;
		background-color: $color-grey-200;
		color: $color-grey-700;
	}

	&.is-loading::after {
		border-color: transparent transparent $color-grey-400 $color-grey-400 !important;
	}
	&.is-outlined {
		color: $color-grey-700;

		&.is-selected,
		&.is-hovered,
		&:hover {
			border-color: $color-grey-500;
			background-color: $color-grey-200;
			color: $color-grey-700;
		}
	}
}

.button.is-delete,
button.is-delete {
	background-color: rgba($black, 0.2);
	color: $white;
	border: none;
	border-radius: 9999px !important;
	cursor: pointer;
	padding: 0;
	width: 1.25rem;
	height: 1.25rem;
	max-width: 1.25rem;
	max-height: 1.25rem;
	min-width: 1.25rem;
	min-height: 1.25rem;
	vertical-align: top;

	&.is-small {
		height: 1rem;
		max-height: 1rem;
		max-width: 1rem;
		min-height: 1rem;
		min-width: 1rem;
		width: 1rem;
	}

	&.is-focus-visible,
	&:focus-visible,
	&.is-selected,
	&.is-hovered,
	&:hover {
		background-color: rgba($black, 0.4);
		outline: none;
	}

	.icon {
		height: 1rem;
		width: 1rem;
	}
}

@mixin button-styles($bgColor, $textColor, $hoverBgColor, $hoverTextColor) {
	border-color: $bgColor;
	background-color: $bgColor;
	color: $textColor;

	&.is-selected,
	&.is-hovered,
	&:hover {
		border-color: transparent;
		background-color: $hoverBgColor;
		color: $hoverTextColor;
	}

	&.is-focused,
	&:focus {
		box-shadow: 0 0 0 0.125em rgba($bgColor, 0.25);
		background-color: $bgColor;
		color: $textColor;
	}

	&.is-outlined {
		background-color: transparent;
		color: $bgColor;

		&.is-selected,
		&.is-hovered,
		&:hover {
			border-color: transparent;
			background-color: $bgColor;
			color: $textColor;
		}

		&.is-focus-visible,
		&:focus-visible {
			background-color: transparent;
			color: $bgColor;
		}

		&.is-loading::after {
			border: 2px solid $bgColor;
			border-right-color: transparent;
			border-top-color: transparent;
			border-color: transparent transparent $bgColor $bgColor !important;
		}
	}

	&.is-loading::after {
		border-color: transparent transparent $textColor $textColor !important;
	}

	&.is-light {
		background-color: rgba($bgColor, 0.1);
		border-color: transparent !important;
		color: $hoverBgColor;

		&.is-selected,
		&.is-hovered,
		&:hover {
			background-color: rgba($hoverBgColor, 0.2);
		}
	}
}

.button.is-primary,
button.is-primary {
	@include button-styles(
		$color-primary-500,
		$color-on-primary-500,
		$color-primary-600,
		$color-on-primary-600
	);
}
.button.is-success,
button.is-success {
	@include button-styles(
		$color-success-500,
		$color-on-success-500,
		$color-success-600,
		$color-on-success-600
	);
}
.button.is-danger,
button.is-danger {
	@include button-styles(
		$color-danger-500,
		$color-on-danger-500,
		$color-danger-600,
		$color-on-danger-600
	);
}
.button.is-info,
button.is-info {
	@include button-styles(
		$color-info-500,
		$color-on-info-500,
		$color-info-600,
		$color-on-info-600
	);
}
.button.is-warning,
button.is-warning {
	@include button-styles(
		$color-warning-500,
		$color-on-warning-500,
		$color-warning-600,
		$color-on-warning-600
	);
}

.button.is-grey,
button.is-grey {
	border-color: $color-grey-400;
	background-color: $white;
	color: $color-grey-700;

	&[disabled] {
		border-color: $color-grey-200;
	}

	&.is-selected,
	&.is-hovered,
	&:hover {
		border-color: $color-grey-500;
		background-color: $color-grey-200;
		color: $color-grey-700;
	}

	&.is-outlined {
		background-color: transparent;
		color: $color-grey-700;

		&.is-selected,
		&.is-hovered,
		&:hover {
			background-color: $color-grey-200;
			color: $color-grey-700;
		}

		&.is-focus-visible,
		&:focus-visible {
			background-color: transparent;
			color: $color-grey-700;
		}
	}

	&.is-loading::after {
		border-color: transparent transparent $color-grey-400 $color-grey-400 !important;
	}
}
</style>
