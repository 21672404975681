function getRegisterTypes(accessType = null) {
	switch (accessType) {
		case "REMOTE_WRITE_ONLY":
			return [
				{
					label: "Input Discrete",
					value: "INPUT_DISCRETE"
				},
				{
					label: "Input Register",
					value: "INPUT_REGISTER"
				}
			];

		default:
			return [
				{
					label: "Input Discrete",
					value: "INPUT_DISCRETE"
				},
				{
					label: "Input Register",
					value: "INPUT_REGISTER"
				},
				{
					label: "Holding Register",
					value: "HOLDING_REGISTER"
				},
				{ label: "Coil", value: "COIL" }
			];
	}
}

function getDataFormats(
	registerType = null,
	modbusFunction = null,
	showUInt64 = false,
	showF64 = false,
	oldFormat = null
) {
	if (["INPUT_DISCRETE", "COIL"].includes(registerType)) {
		return [{ label: "flag", value: "flag" }];
	}

	if (!modbusFunction) {
		return [];
	}
	switch (modbusFunction) {
		case "READ_COILS":
		case "WRITE_SINGLE_COIL":
			return [{ label: "uint16", value: "uint16" }];

		case "WRITE_SINGLE_REGISTER":
			return [
				{ label: "uint16", value: "uint16" },
				{ label: "int16", value: "int16" }
			];
		default:
			return [
				{ label: "int16", value: "int16" },
				{ label: "int32_abcd", value: "int32_abcd" },
				{ label: "int32_badc", value: "int32_badc" },
				{ label: "int32_cdab", value: "int32_cdab" },
				{ label: "int32_dcba", value: "int32_dcba" },
				{ label: "int64_be", value: "int64_be" },
				showUInt64 && {
					label: "int64_le",
					value: "int64_le"
				},
				{ label: "uint16", value: "uint16" },
				{ label: "uint32_abcd", value: "uint32_abcd" },
				{ label: "uint32_badc", value: "uint32_badc" },
				{ label: "uint32_cdab", value: "uint32_cdab" },
				{ label: "uint32_dcba", value: "uint32_dcba" },
				{ label: "uint64_be", value: "uint64_be" },
				showUInt64 && {
					label: "uint64_le",
					value: "uint64_le"
				},
				oldFormat && {
					label: oldFormat.label,
					value: oldFormat.value
				},
				// Seems to be old format
				// { label: "float_abcd", value: "float_abcd" },
				// { label: "float_badc", value: "float_badc" },
				// { label: "float_cdab", value: "float_cdab" },
				// { label: "float_dcba", value: "float_dcba" },
				{ label: "f32_abcd", value: "f32_abcd" },
				{ label: "f32_badc", value: "f32_badc" },
				{ label: "f32_cdab", value: "f32_cdab" },
				{ label: "f32_dcba", value: "f32_dcba" },
				showF64 && {
					label: "f64_be",
					value: "f64_be"
				},
				showF64 && {
					label: "f64_le",
					value: "f64_le"
				},
				{ label: "raw_bytes", value: "raw_bytes" },

				{ label: "text", value: "text" },
				{ label: "flag", value: "flag" }
			].filter(Boolean);
	}
}

const readFunctions = [
	{
		label: "Read Coils (Function 1)",
		value: "READ_COILS"
	},
	{
		label: "Read Discrete Inputs (Function 2)",
		value: "READ_DISCRETE_INPUTS"
	},
	{
		label: "Read Holding Registers (Function 3)",
		value: "READ_HOLDING_REGISTERS"
	},
	{
		label: "Read Input Registers (Function 4)",
		value: "READ_INPUT_REGISTERS"
	}
];
const writeFunctions = [
	{
		label: "Write Single Coil (Function 5)",
		value: "WRITE_SINGLE_COIL"
	},
	{
		label: "Write Single Register (Function 6)",
		value: "WRITE_SINGLE_REGISTER"
	},
	{
		label: "Write Multiple Registers (Function 16)",
		value: "WRITE_MULTIPLE_REGISTERS"
	}
];

const readWriteFunctions = [
	{
		label: "Read Coils (Function 1)",
		value: "READ_COILS"
	},
	{
		label: "Read Holding Registers (Function 3)",
		value: "READ_HOLDING_REGISTERS"
	}
];

function getMainFunction(accessType = null) {
	if (!accessType) {
		return [];
	}

	switch (accessType) {
		case "REMOTE_READ_ONLY":
			return this.readFunctions;
		case "REMOTE_READ_WRITE":
			return this.readWriteFunctions;
		case "REMOTE_WRITE_ONLY":
			return this.writeFunctions;
		case "LOCAL":
		default:
			return [...this.readFunctions, ...this.writeFunctions];
	}
}

export {
	readFunctions,
	writeFunctions,
	readWriteFunctions,
	getRegisterTypes,
	getDataFormats,
	getMainFunction
};
