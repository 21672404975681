<template>
	<main
		class="panel-main"
		:class="{ 'overflow-visible': overflowVisible }"
		ref="scrollContainer"
	>
		<slot />
	</main>
</template>

<script>
import { EventBus } from "@/eventbus";

export default {
	name: "CardMain",

	data() {
		return {
			overflowVisible: false
		};
	},

	created() {
		EventBus.$on("ws-form-select-opened", this.changeOverflowVisible);
	},

	unmounted() {
		EventBus.$off("ws-form-select-opened", this.changeOverflowVisible);
	},

	methods: {
		/**
		 * This will turn overflow to visible to show the entire vue-multiselect component
		 */
		changeOverflowVisible(status) {
			this.overflowVisible = status;
		}
	}
};
</script>

<style lang="scss" scoped>
main.panel-main {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	padding: 1.5rem 1rem;
	overflow-x: hidden;
	position: relative;
	width: 100%;

	&.overflow-visible {
		overflow-y: visible !important;
	}
}
</style>
