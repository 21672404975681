<template>
	<div
		class="modal is-active"
		v-if="showModal"
		:data-testid="`modal${(props.isCard && '-card') || ''}`"
	>
		<div class="modal-background" />
		<div
			:class="{
				'is-success': props.type === 'success',
				'is-warning': props.type === 'warning',
				'is-danger': props.type === 'danger',
				'is-info': props.type === 'info',
				'is-primary':
					props.type === 'primary' || props.type === 'default',

				'modal-content': !props.isCard,
				'modal-wrapper': props.isCard,
				'is-large': props.isLarge,
				'has-overflow': props.isCard && wsFormSelectOpened
			}"
			ref="modalWrapper"
		>
			<template v-if="props.isCard">
				<div
					class="modalHeader is-flex is-justify-content-space-between"
					v-if="props.showHeader"
				>
					<div class="modalTitle">
						<slot name="modal-title">
							{{ props.title }}
						</slot>
					</div>
					<div class="closeIcon" v-if="props.showCloseButton">
						<ws-icon
							icon="close"
							size="md"
							@click="close()"
							tabindex="0"
							@keyup.enter="close"
						/>
					</div>
				</div>
				<div
					class="modalBody"
					:class="{ 'has-overflow': wsFormSelectOpened }"
				>
					<slot />
				</div>
				<div class="modalFooter" v-if="props.showFooter">
					<slot name="above-footer-buttons" />
					<div class="button-actions">
						<div class="footerLeft">
							<slot name="footer-left" />
						</div>
						<div class="footerRight">
							<slot
								name="footer-buttons"
								:cancel="close"
								:save="save"
							>
								<ws-button
									is-grey
									@click="close()"
									data-testid="ws-modal-cancel-btn"
								>
									{{ textCancelButton }}
								</ws-button>
								<ws-button
									:is-success="props.type === 'success'"
									:is-warning="props.type === 'warning'"
									:is-danger="props.type === 'danger'"
									:is-info="props.type === 'info'"
									:is-primary="
										props.type === 'primary' ||
										props.type === 'default'
									"
									@click="save()"
									data-testid="ws-modal-confirm-btn"
									v-if="showSaveButton"
								>
									{{ textSaveButton }}
								</ws-button>
							</slot>
						</div>
					</div>
				</div>
			</template>
			<div class="box" v-else-if="!$slots.content">
				<slot />
			</div>
			<slot name="content" />
		</div>
		<button
			v-if="!props.isCard && props.showCloseButton"
			class="modal-close is-large"
			aria-label="close"
			@click="save()"
			data-testid="btn-confirm"
		></button>
	</div>
</template>

<script setup>
const emits = defineEmits(["closed"]);
const props = defineProps({
	isCard: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		default: "primary",
		validation: function (value) {
			return [
				"default",
				"primary",
				"info",
				"success",
				"warning",
				"danger"
			].includes(value);
		}
	},
	title: {
		type: String,
		default: ""
	},
	saveButton: {
		type: String,
		default: null
	},
	cancelButton: {
		type: String,
		default: null
	},
	validateContent: {
		type: Function,
		default: () => true
	},
	onSave: {
		type: Function,
		default: () => true
	},
	showHeader: {
		type: Boolean,
		default: true
	},
	showFooter: {
		type: Boolean,
		default: true
	},
	showCloseButton: {
		type: Boolean,
		default: true
	},
	showSaveButton: {
		type: Boolean,
		default: true
	},
	isLarge: {
		type: Boolean,
		default: false
	}
});

import { ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue";
import { EventBus } from "@/eventbus.js";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useI18n } from "vue-i18n";
import { useTrapFocus } from "@/composables/useTrapFocus.js";

const { t: $t } = useI18n();
const { error: $logError } = useLogger();
const { setTrapFocus } = useTrapFocus();
const modalWrapper = ref(null);
const showModal = ref(false);
const resolve = ref(null);
const wsFormSelectOpened = ref(false); // https://github.com/shentao/vue-multiselect/issues/227

onMounted(() => {
	EventBus.$on("ws-form-select-opened", changeFormSelectOpened);
});

onUnmounted(() => {
	EventBus.$off("ws-form-select-opened", changeFormSelectOpened);
});

function changeFormSelectOpened(status) {
	wsFormSelectOpened.value = status;
}

async function open() {
	return new Promise((_resolve) => {
		resolve.value = _resolve;
		showModal.value = true;
	});
}

watch(
	() => showModal.value,
	async (_newValue) => {
		// when modal is visible, set Trap focus + listen for ESC keyboard input to close it
		if (_newValue) {
			await nextTick();
			setTrapFocus(modalWrapper?.value, close, 0);
		}
	},
	{ immediate: true }
);

async function save() {
	try {
		const resp = await props.validateContent();
		if (resp === false) {
			return;
		}
		const respSave = await props.onSave();
		if (respSave === false) {
			return;
		}
		close(respSave || true);
	} catch (err) {
		$logError("ws-modal SAVE()", err);
		return;
	}
}

function close(args = false) {
	resolve.value(args);
	emits("closed", args);
	showModal.value = false;
}

const textSaveButton = computed(() => {
	return props.saveButton || $t("save");
});

const textCancelButton = computed(() => {
	return props.cancelButton || $t("cancel");
});

defineExpose({
	open,
	close
});
</script>

<style lang="scss" scoped>
// https://github.com/shentao/vue-multiselect/issues/227
.has-overflow {
	overflow: visible;
}

.modal-content.is-large {
	width: 85vw;
}

.modal-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;

	margin: 0 auto;
	max-height: calc(100vh - 40px);
	width: calc(100% - 40px);
	max-width: 40rem;

	&.is-large {
		width: 85vw;
		max-width: 85vw;
	}

	&.is-primary .modalHeader {
		background-color: $color-primary-500;
		color: $color-on-primary-500;
	}
	&.is-info .modalHeader {
		background-color: $color-info-500;
		color: $color-on-info-500;
	}
	&.is-success .modalHeader {
		background-color: $color-success-500;
		color: $color-on-success-500;
	}
	&.is-warning .modalHeader {
		background-color: $color-warning-500;
		color: $color-on-warning-500;
	}
	&.is-danger .modalHeader {
		background-color: $color-danger-500;
		color: $color-on-danger-500;
	}
	.modalHeader {
		padding: 1rem;
		position: relative;

		width: 100%;
		line-height: 2rem;

		.modalTitle {
			margin: 0;
			font-weight: $weight-semibold;
			font-size: $size-4;
		}

		.closeIcon {
			height: 2rem;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		.closeIcon .ws-icon:focus-visible {
			outline: $focus-outline-color auto 2px;
			outline-offset: 2px;
		}
	}
	.modalBody {
		background-color: $white;

		overflow: auto;
		padding: 1rem;
	}
	.modalFooter {
		background-color: $white;
		border-top: 1px solid $color-grey-300;
		padding: 1rem;

		width: 100%;

		.button-actions {
			// is-flex is-justify-content-space-between
			display: flex;
			justify-content: space-between;

			@include mobile {
				display: block;
				.footerLeft {
					margin-right: 0;
					margin-bottom: 1rem;
				}
			}

			.footerLeft {
				// to vertically align footerLeft & footerRight
				display: flex;
				align-items: center;
				margin-right: 1rem;
			}
			.footerRight {
				display: flex;
				flex-direction: row;
				.button:not(:first-child) {
					margin-left: 1rem;
				}
				@include mobile {
					flex-direction: column;
					align-items: flex-end;
					.button {
						margin-left: 0;
					}
					.button:not(:last-child) {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}
</style>
