<template>
	<div class="ws-view-title">
		<div class="content">
			<div class="is-inline-flex is-align-items-center">
				<template v-if="backTo !== null">
					<ws-button
						is-primary
						is-outlined
						is-small
						v-if="isBackToFunction"
						data-testid="back-button"
						:to="backTo"
						@click="backTo"
					>
						<template #icon>
							<ws-icon icon="arrow-left" />
						</template>
					</ws-button>
					<ws-button
						v-else
						data-testid="back-button"
						@click="goBackFct"
						is-primary
						is-outlined
						is-small
					>
						<template #icon>
							<ws-icon icon="arrow-left" />
						</template>
					</ws-button>
				</template>
				<span class="is-size-5 title" data-testid="title">
					<!-- @slot Title  -->
					<slot>
						{{ title }}
					</slot>
				</span>
			</div>

			<div class="right-side" v-if="$slots['right-side']">
				<slot name="right-side" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "WsViewTitle",

	props: {
		title: {
			type: String,
			default: null
		},
		/**
		 * <router-link :to="...."> OR if a function, it will be called when click
		 * @see See [router-link to API documentation](https://router.vuejs.org/api/#to)
		 */
		backTo: {
			type: [Object, String, Function],
			default: null
		}
	},

	methods: {
		goBackFct() {
			this.$router.push(this.backTo);
		}
	},

	computed: {
		isBackToFunction() {
			return typeof this.backTo === "function";
		}
	}
};
</script>

<style lang="scss" scoped>
.ws-view-title {
	margin: 1.25rem auto;
	& > div {
		align-items: center;
	}

	a,
	span {
		vertical-align: middle;
	}

	a,
	.button {
		margin-right: 15px;
	}

	.content {
		display: flex;
		flex-direction: row;

		@include mobile {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.right-side {
		display: inline-flex;
		justify-content: flex-end;
		flex-grow: 2;
		margin-top: 0;

		@include mobile {
			margin-top: 10px;
		}
	}
}
</style>
