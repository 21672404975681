<template>
	<div
		class="dropzone ws-csv-dropzone"
		:class="{ 'dropzone-no-area': hasErrors || success }"
		:id="id"
		data-testid="ws-csv-dropzone"
	>
		<div class="dz-message px-2 py-4">
			<div class="dz-input">
				<template v-if="success">
					<ws-icon
						icon="check-circle"
						size="6x"
						class="has-text-success"
					></ws-icon>
					<p class="mb-1 is-size-5 has-text-weight-semibold">
						<slot name="success-message">
							{{ $t("import-success") }}
						</slot>
					</p>
				</template>
				<template v-else-if="hasErrors">
					<ws-icon
						icon="alert"
						size="6x"
						class="has-text-danger"
					></ws-icon>
					<p class="mb-1 is-size-5 has-text-weight-semibold">
						{{ $t("import-again") }}
					</p>
				</template>
				<template v-else>
					<ws-icon
						icon="upload"
						size="6x"
						class="mb-2 has-text-grey"
					></ws-icon>
					<p class="mb-1">
						{{ $t("drag-drop-here") }}
					</p>
					<p class="is-lowercase">{{ $t("or") }}</p>
				</template>

				<ws-form-file-input
					class="mx-auto my-4"
					v-model="uploadedFile"
					:is-primary="!uploadedFile"
					disabled
					can-delete
					placeholder=""
					:hide-file-name="!uploadedFile"
					use-button
				/>
				<slot name="errors"></slot>
				<div
					class="mb-2 is-size-7 has-text-grey"
					:class="{ 'has-text-danger': errorFormat }"
				>
					{{ formatInfoLabel }}
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from "vue";
import Dropzone from "dropzone";
import "dropzone/dist/min/dropzone.min.css";
import { generateRandomId } from "@/helpers/functions.helper.js";
import { useWsDropzone } from "./helpers/useWsDropzone.js";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useXlsx } from "@/composables/useXlsx.js";
import { useCsv } from "@/composables/useCsv.js";

const emit = defineEmits(["failed", "uploaded-list", "uploaded-file"]);
const props = defineProps({
	objectType: {
		type: String,
		required: true
	},
	success: {
		type: Boolean,
		required: false,
		default: false
	},
	fileType: {
		type: String,
		required: false,
		default: "CSV",
		validator: (value) => ["XLSX", "CSV"].includes(value)
	}
});

onMounted(() => {
	Dropzone.autoDiscover = false;
	const dropzoneConfig = {
		maxFiles: 1,
		maxFilesize: 1,
		dictDefaultMessage: $t("drag-file-here"),
		dictFileTooBig: $t("file-too-big"),
		acceptedFiles: "*",
		url: "/",
		autoProcessQueue: false
	};
	myDropzone.value = new Dropzone(`div#${id.value}`, dropzoneConfig);
	myDropzone.value.on("addedfile", (file) => {
		if (file?.size > dropzoneConfig.maxFilesize * 1024 * 1024) {
			const errorMessage = $t("file-too-big-size", {
				sizeMB: dropzoneConfig.maxFilesize
			});
			toast.error(errorMessage);
			emit("failed", errorMessage);
			myDropzone.value.removeFile(file);
			return;
		}
		uploadedFile.value = file;
		fileSelected(file);
		myDropzone.value.removeFile(file);
	});
});

const dropZoneOptions = computed(() => ({ objectType: props.objectType }));
const { requiredHeaders, allowedHeaders, config } =
	useWsDropzone(dropZoneOptions);

const { t: $t } = useI18n();
const toast = useToast();

const uploadedFile = ref(null);
const myDropzone = ref(null);
const errorFormat = ref(false);
const hasErrors = ref(false);
const id = computed(() => {
	return `ws-dropzone-${generateRandomId()}`;
});

const isCsvFile = computed(() => props.fileType === "CSV");
const isXlsxFile = computed(() => props.fileType === "XLSX");

const formatInfoLabel = computed(() =>
	isXlsxFile.value ? $t("xlsx-file-format") : $t("csv-file-format")
);

const csv = useCsv();
const xslx = useXlsx();

/**
 * Not convinced to bring in headers from another composable,
 * but no other ideas for the moment
 */
watch(
	requiredHeaders,
	(newRequiredHeaders) => {
		csv.requiredHeaders.value = newRequiredHeaders;
		xslx.requiredHeaders.value = newRequiredHeaders;
	},
	{ immediate: true }
);
watch(
	allowedHeaders,
	(newAllowedHeaders) => {
		csv.allowedHeaders.value = newAllowedHeaders;
		xslx.allowedHeaders.value = newAllowedHeaders;
	},
	{ immediate: true }
);

watch(uploadedFile, (newFile) => {
	if (!newFile) {
		hasErrors.value = false;
		errorFormat.value = false;
		emit("failed", null);
	}
});

watch(
	() => props.objectType,
	(newType, oldType) => {
		if (newType !== oldType) {
			hasErrors.value = false;
			errorFormat.value = false;
			uploadedFile.value = null;
		}
	}
);

function fileSelected(file) {
	errorFormat.value = false;
	hasErrors.value = false;
	const reader = new FileReader();
	reader.onload = async (loadEvent) => {
		try {
			let list = [];
			if (isCsvFile.value) {
				const delimiter = csv.getDelimiterAndValidateHeader(
					loadEvent.target.result
				);
				const content = csv.validateFileContent(
					loadEvent.target.result,
					delimiter
				);
				list = config.value.formatToPreview(content, props.objectType);
			} else if (isXlsxFile.value) {
				const validFile = await xslx.validateFile(
					loadEvent.target.result
				);
				list = config.value.formatToPreview(
					validFile,
					props.objectType
				);
			}

			emit("failed", null);
			emit("uploaded-list", list);
			emit("uploaded-file", loadEvent.target.result);
		} catch (err) {
			hasErrors.value = true;
			emit("failed", err?.message || "Missing fields on CSV");
		}
	};

	const isCsvExtension =
		isCsvFile.value &&
		(file.type === "text/csv" || file?.name?.endsWith(".csv"));

	const isXlsxExtension = isXlsxFile.value && file?.name?.endsWith(".xlsx");

	if (isCsvExtension || isXlsxExtension) {
		reader.readAsBinaryString(file);
	} else {
		errorFormat.value = true;
		hasErrors.value = true;
		emit("failed", $t("wrong-file-format"));
	}
}
</script>
<style lang="scss" scoped>
.dropzone.ws-csv-dropzone {
	background-color: $color-grey-100;
	max-width: 580px;
	margin: 0 auto;
	border-color: $color-grey-300;
	border-style: dashed;
	border-radius: 0.5em;
	height: 100%;
	padding: 0;

	&.dropzone-no-area {
		background-color: $white;
		border: none;
	}

	.dz-message {
		.dz-input {
			margin: auto;
			max-width: 30em;

			.field {
				width: fit-content;
			}
		}
		.dz-button {
			max-width: 20em !important;
			white-space: pre-line;
			background: $color-primary-500;
			border-radius: 5em;
			width: 10em;
			height: 10em;
			padding: 1em;
		}
		background: $white-o70;
		align-items: center;
		margin: 0;
	}
}
</style>
