<template>
	<span
		class="text-link"
		:class="{
			'is-copy': props.isCopy,
			'is-link': props.isLink,
			'is-discrete': props.isDiscrete,
			'is-disabled': props.isDisabled
		}"
		v-tooltip="copyTooltip"
		@mouseover="hovered = true"
		@mouseleave="hovered = false"
		@click="copyTxt"
	>
		<span class="icon left" v-if="hasIcon && iconPosition === 'left'">
			<slot name="icon">
				<ws-icon
					data-testid="copy-icon"
					icon="clone"
					size="sm"
					v-if="props.isCopy"
				/>
				<ws-icon
					data-testid="link-icon"
					icon="external-link-alt"
					size="sm"
					v-if="props.isLink"
				/>
			</slot>
		</span>
		<slot />
		<span class="icon right" v-if="hasIcon && iconPosition === 'right'">
			<slot name="icon">
				<ws-icon
					data-testid="copy-icon"
					icon="clone"
					size="sm"
					v-if="props.isCopy"
				/>
				<ws-icon
					data-testid="link-icon"
					icon="external-link-alt"
					size="sm"
					v-if="props.isLink"
				/>
			</slot>
		</span>
	</span>
</template>
<script setup>
import { copyToClipboard } from "@/helpers/functions.helper.js";
import { computed, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();

const emits = defineEmits(["click"]);

const slots = useSlots();

const copied = ref(false);
const hovered = ref(false);
const timeout = ref(null);

const props = defineProps({
	isLink: {
		type: Boolean,
		default: false
	},
	isCopy: {
		type: Boolean,
		default: false
	},
	isDiscrete: {
		type: Boolean,
		default: false
	},
	showIcon: {
		type: Boolean,
		default: true
	},
	iconPosition: {
		type: String,
		default: "right",
		validation: function (value) {
			return ["right", "left"].includes(value);
		}
	},
	textToCopy: {
		type: String,
		default: null
	},
	isDisabled: {
		type: Boolean,
		default: false
	}
});

const textToCopy = computed(() => {
	if (!props.isCopy) {
		return null;
	}

	if (props.textToCopy) {
		return props.textToCopy;
	}

	// get text from <slot>
	const textFromSlot = slots.default()[0].children.toString().trim();
	return textFromSlot;
});

const hasIcon = computed(() => {
	return props.showIcon && !props.isDiscrete;
});
const shouldShowTooltip = computed(() => copied.value || hovered.value);
const copyTooltip = computed(() => {
	if (!textToCopy.value || !shouldShowTooltip.value) {
		return null;
	}
	return {
		content: copied.value ? $t("copied-to-clipboard") : $t("click-to-copy"),
		placement: "top-end",
		distance: 0,
		delay: {
			show: 200,
			hide: 0
		},
		theme: "no-arrow",
		triggers: [],
		hideTriggers: [],
		shown: shouldShowTooltip.value,
		autoHide: false
	};
});

const copyTxt = async () => {
	if (!textToCopy.value || copied.value) {
		emits("click", null);
		return;
	}
	const resp = await copyToClipboard(textToCopy.value);
	if (resp) {
		if (timeout.value) {
			clearTimeout(timeout.value);
		}
		copied.value = true;
		emits("click", textToCopy.value);
		timeout.value = setTimeout(() => {
			copied.value = false;
		}, 2000);
	}
};
</script>
<style lang="scss" scoped>
.text-link {
	cursor: pointer;
	padding: 0 0.5rem;
	border-radius: 5px;
	&.is-disabled {
		cursor: default;
	}
}

.icon.right {
	padding-left: 0.25rem;
	width: auto;
}
.icon.left {
	padding-right: 0.25rem;
	width: auto;
}
.is-link:not(.is-discrete) {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
.is-link.is-discrete:hover {
	text-decoration: underline;
}

.is-copy:hover {
	background-color: $hover-bg;
}
</style>
