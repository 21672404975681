import { GatewayProtocols } from "@/helpers/protocols.helper";
import PropertiesService from "@/services/v1/Properties.service";

export const ID = "Redirected property Id";
export const GATEWAY = "Redirected property Gateway";
export const NAME = "Redirected property Name";
export const DESCRIPTION = "Redirected property Description";
export const SLUG = "Redirected property Slug";
export const ACCESS_TYPE = "Redirected property Access Type";
export const FROM = "Redirected property From";
export const TO = "Redirected property To";
export const UNIT = "Redirected property Unit";
export const SYNC = "Redirected property Synchronized";

// ModBus specific
export const REGISTER_ADDRESS = "Redirected property Register Address";
export const REGISTER_TYPE = "Redirected property Register Type";
export const DATA_FORMAT = "Redirected property Data Format";
export const NB_RAW_REGISTERS = "Redirected property Raw Register";

// BACnet specific
export const OBJECT_TYPE = "Redirected property Object Type";
export const OBJECT_INSTANCE = "Redirected property Object Instance";

// MQTT specific
export const MQTT_SLUG = "Redirected property MQTT Slug";

export const ERRORS = "errors";

export const requiredFileHeaders = [];

export const fileHeaders = [
	ID,
	GATEWAY,
	NAME,
	DESCRIPTION,
	SLUG,
	ACCESS_TYPE,
	FROM,
	TO,
	UNIT,
	SYNC
];

export const allowedFileHeaders = [
	...fileHeaders,
	REGISTER_ADDRESS,
	REGISTER_TYPE,
	DATA_FORMAT,
	NB_RAW_REGISTERS,
	OBJECT_TYPE,
	OBJECT_INSTANCE,
	MQTT_SLUG,
	ERRORS
];

/**
 * Map template file header to Wattsense header (used in the code) - but only the different header names.
 * File headers to Datatable headers
 * ex: "Manufacturer" => "name"
 */
export const headerToApiKeys = {
	[ID]: "propertyId",
	[NAME]: "name",
	[DESCRIPTION]: "description",
	[GATEWAY]: "gateway",
	[SLUG]: "slug",
	[ACCESS_TYPE]: "accessType",
	[FROM]: "from",
	[TO]: "to",
	[UNIT]: "unit",
	[SYNC]: "sync",

	[REGISTER_ADDRESS]: "modbusRegisterAddress",
	[REGISTER_TYPE]: "modbusRegisterType",
	[DATA_FORMAT]: "modbusDataFormat",
	[NB_RAW_REGISTERS]: "modbusNumberOfRawRegisters",

	[OBJECT_TYPE]: "bacnetObjectType",
	[OBJECT_INSTANCE]: "bacnetObjectInstance",

	[MQTT_SLUG]: "mqttSlug"
};

export function wsHeaderFromTemplate(templateName) {
	return headerToApiKeys[templateName] || templateName;
}

/**
 * Map Wattsense header (used in the code) to template file header - but only the different header names.
 * Datatable headers to CSV File headers
 */
export const mapWattsenseHeaderToTemplateHeader = Object.entries(
	headerToApiKeys
).reduce(
	(acc, [key, value]) => ({
		...acc,
		[value]: key
	}),
	{}
);

export function templateHeaderFromWattsense(wsName) {
	return mapWattsenseHeaderToTemplateHeader[wsName] || wsName;
}
/*
 * Convert File entries to equipment object for preview
 */
export function formatLine(row) {
	try {
		const formatted = {
			[wsHeaderFromTemplate(ID)]: row[ID],
			[wsHeaderFromTemplate(NAME)]: row[NAME],
			[wsHeaderFromTemplate(DESCRIPTION)]: row[DESCRIPTION],
			[wsHeaderFromTemplate(GATEWAY)]: row[GATEWAY],
			[wsHeaderFromTemplate(SLUG)]: row[SLUG],
			[wsHeaderFromTemplate(ACCESS_TYPE)]: row[ACCESS_TYPE],
			[wsHeaderFromTemplate(FROM)]: row[FROM],
			[wsHeaderFromTemplate(TO)]: row[TO],
			[wsHeaderFromTemplate(UNIT)]: row[UNIT],
			[wsHeaderFromTemplate(SYNC)]: row[SYNC],

			[wsHeaderFromTemplate(REGISTER_ADDRESS)]: row[REGISTER_ADDRESS],
			[wsHeaderFromTemplate(REGISTER_TYPE)]: row[REGISTER_TYPE],
			[wsHeaderFromTemplate(DATA_FORMAT)]: row[DATA_FORMAT],
			[wsHeaderFromTemplate(NB_RAW_REGISTERS)]: row[NB_RAW_REGISTERS],

			[wsHeaderFromTemplate(OBJECT_TYPE)]: row[OBJECT_TYPE],
			[wsHeaderFromTemplate(OBJECT_INSTANCE)]: row[OBJECT_INSTANCE],

			[wsHeaderFromTemplate(MQTT_SLUG)]: row[MQTT_SLUG]
		};
		return formatted;
	} catch (err) {
		throw Error("Missing fields on file");
	}
}

export async function formatToFile(item, config = {}) {
	const bacnetObjects = await PropertiesService.getBacnetObjects();

	const equipmentName = (property) => {
		const name = property.name;
		const equipmentName = config.equipments.find(
			(equipment) => equipment.equipmentId === property.equipmentId
		)?.name;
		return `${name} (${equipmentName})`;
	};
	const redirectTos = config.properties
		.filter((property) =>
			item.redirectToProperties?.includes(property.propertyId)
		)
		.map(equipmentName)
		.join(", ");

	const redirectFroms = config.properties
		.filter((property) =>
			property.redirectToProperties?.includes(item.propertyId)
		)
		.map(equipmentName)
		.join(", ");

	const row = {
		[wsHeaderFromTemplate(ID)]: item.propertyId,
		[wsHeaderFromTemplate(GATEWAY)]: config.gatewayInterfaces?.find(
			(gw) => gw.gatewayInterfaceId === item.gatewayInterfaceId
		)?.name,
		[wsHeaderFromTemplate(NAME)]: item.name,
		[wsHeaderFromTemplate(DESCRIPTION)]: item.description,
		[wsHeaderFromTemplate(SLUG)]: item.slug,
		[wsHeaderFromTemplate(ACCESS_TYPE)]: item.accessType,
		[wsHeaderFromTemplate(FROM)]: redirectFroms, // search in properties
		[wsHeaderFromTemplate(TO)]: redirectTos, // search in redirectToProperties
		[wsHeaderFromTemplate(UNIT)]: item.unit,
		[wsHeaderFromTemplate(SYNC)]:
			item.__status !== "current" ? "DRAFT" : "CURRENT"
	};
	switch (item.config.protocol) {
		case GatewayProtocols.MQTT_GATEWAY.value:
			row[wsHeaderFromTemplate(MQTT_SLUG)] = item.config.mqttSlug;
			break;
		case GatewayProtocols.MODBUS_IP_GATEWAY.value:
			row[wsHeaderFromTemplate(REGISTER_ADDRESS)] =
				item.config.modbusRegisterAddress;
			row[wsHeaderFromTemplate(REGISTER_TYPE)] =
				item.config.modbusRegisterType;
			row[wsHeaderFromTemplate(DATA_FORMAT)] =
				item.config.modbusDataFormat;
			row[wsHeaderFromTemplate(NB_RAW_REGISTERS)] =
				item.config.modbusNumberOfRawRegisters;
			break;
		case GatewayProtocols.BACNET_IP_GATEWAY.value:
			row[wsHeaderFromTemplate(OBJECT_TYPE)] = bacnetObjects.find(
				(obj) => obj.identifier === item.config.bacnetObjectType
			)?.name;
			row[wsHeaderFromTemplate(OBJECT_INSTANCE)] =
				item.config.bacnetObjectInstance;
			break;
		default:
			break;
	}

	return row;
}
