<template>
	<div class="ws-card" data-testid="ws-card" :class="classes" @click="focus">
		<div v-if="!!slots.icon" class="ws-card__icon">
			<slot name="icon"></slot>
		</div>
		<div
			class="ws-card__container"
			:class="{
				'is-flex is-align-items-center': !slots.title
			}"
		>
			<p v-if="!!slots.title" class="is-size-6 title ws-card__title mb-2">
				<slot name="title"></slot>
			</p>
			<p>
				<slot> </slot>
			</p>
		</div>
	</div>
</template>

<script setup>
import { useSlots, computed } from "vue";

const props = defineProps({
	focused: {
		type: Boolean,
		required: false,
		default: false
	},
	disabled: {
		type: Boolean,
		required: false,
		default: false
	}
});
const emits = defineEmits(["focus"]);

const slots = useSlots();
const classes = computed(() => {
	return {
		"ws-card--with-icon": !!slots.icon,
		"ws-card--focused": props.focused && !props.disabled,
		"ws-card--disabled": props.disabled
	};
});

const focus = () => {
	if (props.disabled) {
		return;
	}

	emits("focus");
};
</script>

<style lang="scss" scoped>
.ws-card {
	background-color: $white;
	border-radius: 6px;
	border: 1px solid $color-grey-300;
	padding: 16px;
	cursor: pointer;
	transition: all 0.3 ease;
	display: flex;
	height: 100%;

	&__title {
		line-height: 24px;
	}

	&--with-icon {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 80px;
		margin-right: 8px;
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		padding: 8px;
		display: flex;
		justify-content: center;
		color: $color-grey-500;
	}

	&--focused {
		border-color: $color-grey-700;
	}

	&--disabled {
		background-color: $color-grey-200;

		.ws-card__title,
		.ws-card__container {
			color: $color-grey-500;
		}
	}

	&:hover:not(.ws-card--disabled) {
		box-shadow: $box-shadow-radio-card-hover;
	}
}
</style>
