<template>
	<label
		class="label"
		:class="{
			inlineLabel: props.isInline
		}"
		:id="props.id"
		:for="props.for"
	>
		<slot />
		<span v-if="props.optional" class="optional">
			&nbsp;({{ $t("optional") }})
		</span>
		<span
			class="icon ml-1"
			v-tooltip="tooltipObject"
			v-if="tooltipObject !== null"
		>
			<ws-icon icon="info" size="md" />
		</span>
	</label>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
	id: {
		type: String,
		default: null,
		required: true
	},
	for: {
		type: String,
		default: null,
		required: false
	},
	disabled: {
		type: Boolean,
		default: false
	},
	error: {
		type: [String, Boolean],
		default: false
	},
	isInline: {
		type: Boolean,
		default: false
	},
	optional: {
		type: Boolean,
		default: false
	},
	tooltip: {
		type: [String, Object],
		default: null
	},
	tooltipPosition: {
		type: String,
		default: "top",
		validation: function (value) {
			return [
				"top",
				"top-start",
				"top-end",
				"right",
				"right-start",
				"right-end",
				"bottom",
				"bottom-start",
				"bottom-end",
				"left",
				"left-start",
				"left-end"
			].includes(value);
		}
	}
});

const tooltipObject = computed(() => {
	if (!props.tooltip) {
		return null;
	}
	if (typeof props.tooltip === "string") {
		return {
			content: props.tooltip,
			placement: props.tooltipPosition
		};
	}

	return {
		placement: props.tooltip?.tooltipPosition || "top",
		...props.tooltip
	};
});
</script>

<style scoped>
.label {
	display: flex;
	align-items: center;
	text-align: left;
}
.optional {
	font-weight: 400;
	margin-left: 4px;
}
.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
</style>
