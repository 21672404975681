import { Protocols } from "@/helpers/protocols.helper";

export const ID = "Equipment Id";
export const NAME = "Equipment Name";
export const DESCRIPTION = "Equipment Description";
export const PROTOCOL = "Equipment Protocol";
export const SYNC = "Equipment Synchronized";
export const GROUP = "Equipment Group";

// LoRaWAN specific
export const DEV_EUI = "DevEUI";
export const APP_EUI = "AppEUI";
export const APP_KEY = "Application Key";

export const ERRORS = "errors";

export const requiredFileHeaders = [ID];

export const fileHeaders = [ID, NAME, DESCRIPTION, PROTOCOL, SYNC, GROUP];

export const allowedFileHeaders = [
	...fileHeaders,
	DEV_EUI,
	APP_EUI,
	APP_KEY,
	ERRORS
];

/**
 * Map template file header to Wattsense header (used in the code) - but only the different header names.
 * CSV file headers to Datatable headers
 * ex: "Equipment Id" => "equipmentId"
 */
export const headerToApiKeys = {
	[ID]: "equipmentId",
	[NAME]: "name",
	[DESCRIPTION]: "description",
	[PROTOCOL]: "protocol",
	[SYNC]: "sync",
	[GROUP]: "group",
	[DEV_EUI]: "devEUI",
	[APP_EUI]: "appEUI",
	[APP_KEY]: "appKey",
	[ERRORS]: "errors"
};

export function wsHeaderFromTemplate(templateName) {
	return headerToApiKeys[templateName] || templateName;
}

/**
 * Map Wattsense header (used in the code) to template file header - but only the different header names.
 * Datatable headers to CSV File headers
 */
export const mapWattsenseHeaderToTemplateHeader = Object.entries(
	headerToApiKeys
).reduce(
	(acc, [key, value]) => ({
		...acc,
		[value]: key
	}),
	{}
);

export function templateHeaderFromWattsense(wsName) {
	return mapWattsenseHeaderToTemplateHeader[wsName] || wsName;
}
/*
 * Convert row entries to equipment object for preview
 */
export function formatLine(row) {
	try {
		const formatted = {
			[wsHeaderFromTemplate(ID)]: row[ID],
			[wsHeaderFromTemplate(NAME)]: row[NAME],
			[wsHeaderFromTemplate(DESCRIPTION)]: row[DESCRIPTION],
			[wsHeaderFromTemplate(PROTOCOL)]: row[PROTOCOL],
			[wsHeaderFromTemplate(SYNC)]: row[SYNC],
			[wsHeaderFromTemplate(GROUP)]: row[GROUP],
			[wsHeaderFromTemplate(DEV_EUI)]: row[DEV_EUI],
			[wsHeaderFromTemplate(APP_EUI)]: row[APP_EUI],
			[wsHeaderFromTemplate(APP_KEY)]: row[APP_KEY]
		};
		return formatted;
	} catch (err) {
		throw Error("Missing fields on file");
	}
}

export function formatToFile(item) {
	const row = {
		[wsHeaderFromTemplate(ID)]: item.equipmentId,
		[wsHeaderFromTemplate(NAME)]: item.name,
		[wsHeaderFromTemplate(DESCRIPTION)]: item.description,
		[wsHeaderFromTemplate(PROTOCOL)]: item.config.protocol,
		[wsHeaderFromTemplate(SYNC)]:
			item.__status !== "current" ? "DRAFT" : "CURRENT",
		[wsHeaderFromTemplate(GROUP)]: item.tags?.ws_datatable_group
	};

	if (item.config.protocol === Protocols.LORAWAN_V1_0.value) {
		row[wsHeaderFromTemplate(DEV_EUI)] = item.config.devEUI;
		row[wsHeaderFromTemplate(APP_EUI)] = item.config.appEUI;
		row[wsHeaderFromTemplate(APP_KEY)] =
			item.config.activationMethod.appKey;
	}

	return row;
}
