<template>
	<transition name="slide-fade" mode="out-in" appear>
		<div class="container" ref="cardContainer">
			<card-header @close="close()">
				<slot name="header-title" />
			</card-header>

			<card-main>
				<slot name="main" />
			</card-main>
			<panel-footer
				v-if="
					$slots['footer-above-buttons'] || $slots['footer-buttons']
				"
			>
				<template #above-buttons>
					<slot name="footer-above-buttons" />
				</template>
				<slot name="footer-buttons" />
			</panel-footer>
		</div>
	</transition>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useTrapFocus } from "@/composables/useTrapFocus.js";
import CardHeader from "./card-header.vue";
import CardMain from "./card-main.vue";
import PanelFooter from "@/components/ws-panel/components/panel-footer.vue";

const emits = defineEmits(["close"]);
import { EventBus } from "@/eventbus";

const cardContainer = ref(null);
const { setTrapFocus } = useTrapFocus();
onMounted(async () => {
	await nextTick();
	setTrapFocus(cardContainer?.value, null);

	EventBus.$on("resetFocus", resetFocus);
});

onBeforeUnmount(() => {
	EventBus.$off("resetFocus", resetFocus);
});

const close = () => {
	emits("close");
};
const resetFocus = () => {
	setTrapFocus(cardContainer?.value, close);
};

defineExpose({ close, resetFocus });
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
	transition: all 0.3s ease;
}
.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from, .slide-fade-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */ {
	transform: translateX(20px);
	opacity: 0;
}
.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 100% !important;
}
</style>
