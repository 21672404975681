export const ID = "Property Id";
export const EQUIPMENT = "Property Equipment";
export const NAME = "Property Name";
export const DESCRIPTION = "Property Description";
export const SLUG = "Property Slug";
export const ACCESS_TYPE = "Property Access Type";
export const INTERVAL = "Property Interval";
export const UNIT = "Property Unit";
export const SCALING_A = "Property Scaling A";
export const SCALING_B = "Property Scaling B";
export const TO = "Redirect To";
export const SYNC = "Property Synchronized";
export const STATUS = "Property Activation Status";
export const ERRORS = "errors";
export const equipmentCodecId = "equipmentCodecId";
export const propertyCodecId = "propertyCodecId";
export const equipmentId = "equipmentId";

export const requiredFileHeaders = [];

export const fileHeaders = [
	ID,
	EQUIPMENT,
	NAME,
	DESCRIPTION,
	SLUG,
	ACCESS_TYPE,
	INTERVAL,
	UNIT,
	SCALING_A,
	SCALING_B,
	TO,
	SYNC,
	STATUS
];

export const allowedFileHeaders = [
	...fileHeaders,
	ERRORS,
	equipmentCodecId,
	propertyCodecId,
	equipmentId
];

/**
 * Map template file header to Wattsense header (used in the code) - but only the different header names.
 * CSV file headers to Datatable headers
 * ex: "Manufacturer" => "name"
 */
export const headerToApiKeys = {
	[ID]: "propertyId",
	[EQUIPMENT]: "equipmentName",
	[NAME]: "name",
	[DESCRIPTION]: "description",
	[SLUG]: "slug",
	[ACCESS_TYPE]: "accessType",
	[INTERVAL]: "timer",
	[UNIT]: "unit",
	[SCALING_A]: "scaling_a",
	[SCALING_B]: "scaling_b",
	[TO]: "to",
	[SYNC]: "sync",
	[STATUS]: "disabled",
	[ERRORS]: "errors",
	[equipmentCodecId]: "equipmentCodecId",
	[propertyCodecId]: "propertyCodecId",
	[equipmentId]: "equipmentId"
};

export function wsHeaderFromTemplate(templateName) {
	return headerToApiKeys[templateName] || templateName;
}

/**
 * Map Wattsense header (used in the code) to template file header - but only the different header names.
 * Datatable headers to CSV File headers
 */
export const mapWattsenseHeaderToTemplateHeader = Object.entries(
	headerToApiKeys
).reduce(
	(acc, [key, value]) => ({
		...acc,
		[value]: key
	}),
	{}
);

export function templateHeaderFromWattsense(wsName) {
	return mapWattsenseHeaderToTemplateHeader[wsName] || wsName;
}
/*
 * Convert CSV entries to equipment object for preview
 */
export function formatLine(row) {
	try {
		const formatted = {
			[wsHeaderFromTemplate(equipmentCodecId)]: row[equipmentCodecId],
			[wsHeaderFromTemplate(propertyCodecId)]: row[propertyCodecId],
			[wsHeaderFromTemplate(equipmentId)]: row[equipmentId],
			[wsHeaderFromTemplate(EQUIPMENT)]: row[EQUIPMENT],
			[wsHeaderFromTemplate(ID)]: row[ID],
			[wsHeaderFromTemplate(NAME)]: row[NAME],
			[wsHeaderFromTemplate(DESCRIPTION)]: row[DESCRIPTION],
			[wsHeaderFromTemplate(SLUG)]: row[SLUG],
			[wsHeaderFromTemplate(ACCESS_TYPE)]: row[ACCESS_TYPE],
			[wsHeaderFromTemplate(INTERVAL)]: row[INTERVAL],
			[wsHeaderFromTemplate(UNIT)]: row[UNIT],
			[wsHeaderFromTemplate(SCALING_A)]: row[SCALING_A],
			[wsHeaderFromTemplate(SCALING_B)]: row[SCALING_B],
			[wsHeaderFromTemplate(TO)]: row[TO],
			[wsHeaderFromTemplate(SYNC)]: row[SYNC],
			[wsHeaderFromTemplate(STATUS)]: row[STATUS]
		};
		return formatted;
	} catch (err) {
		throw Error("Missing fields on file");
	}
}

export function formatToFile(item, config = {}) {
	const equipmentName = config.equipments.find(
		(equipment) => equipment.equipmentId === item.equipmentId
	)?.name;

	const redirectTos = config.properties
		.filter(
			(prop) =>
				item.redirectToProperties?.includes(prop.propertyId) &&
				!!prop.gatewayInterfaceId
		)
		.map((prop) => {
			const gatewayName = config.gatewayInterfaces.find(
				(gw) => gw.gatewayInterfaceId === prop.gatewayInterfaceId
			)?.name;

			return `${prop.name} (${gatewayName})`;
		})
		?.join(", ");

	const row = {
		[wsHeaderFromTemplate(equipmentCodecId)]: item.equipmentCodecId,
		[wsHeaderFromTemplate(propertyCodecId)]: item.propertyCodecId,
		[wsHeaderFromTemplate(equipmentId)]: item.equipmentId,
		[wsHeaderFromTemplate(ID)]: item.propertyId,
		[wsHeaderFromTemplate(EQUIPMENT)]: equipmentName,
		[wsHeaderFromTemplate(NAME)]: item.name,
		[wsHeaderFromTemplate(DESCRIPTION)]: item.description,
		[wsHeaderFromTemplate(SLUG)]: item.slug,
		[wsHeaderFromTemplate(ACCESS_TYPE)]: item.accessType,
		[wsHeaderFromTemplate(INTERVAL)]: item.timer,
		[wsHeaderFromTemplate(UNIT)]: item.unit,
		[wsHeaderFromTemplate(SCALING_A)]: item.scaling?.a,
		[wsHeaderFromTemplate(SCALING_B)]: item.scaling?.b,
		[wsHeaderFromTemplate(TO)]: redirectTos,
		[wsHeaderFromTemplate(SYNC)]:
			item.__status !== "current" ? "DRAFT" : "CURRENT",
		[wsHeaderFromTemplate(STATUS)]: item.disabled ? "DISABLE" : "ENABLE"
	};
	return row;
}
