import { findBestMatch } from "string-similarity";
import { parse } from "papaparse";
import i18n from "@/lang/lang.js";
import { ref } from "vue";

export function useCsv() {
	const allowedHeaders = ref([]);
	const requiredHeaders = ref([]);

	function guessCsvHeaderDelimiter(csv) {
		let lines = parse(csv, {
			skipEmptyLines: true,
			delimiter: ","
		}).data;

		if (lines[0].length > 4) {
			return ",";
		}

		lines = parse(csv, {
			skipEmptyLines: true,
			delimiter: ";"
		}).data;
		if (lines[0].length > 4) {
			return ";";
		}

		return "";
	}

	function validateCsvHeader(
		csv,
		delimiter = ",",
		headers = requiredHeaders.value
	) {
		const lines = parse(csv, {
			skipEmptyLines: true,
			delimiter
		}).data;

		const fileHeaders = sanitizeHeader(lines[0]);
		// if there's any item in missingHeadersInFile, means it's missing in the file
		const missingHeadersInFile = [...headers];
		for (const header of fileHeaders) {
			const index = missingHeadersInFile.findIndex(
				(_header) => _header === header
			);
			if (index !== -1) {
				missingHeadersInFile.splice(index, 1);
			}
		}

		if (missingHeadersInFile.length > 0) {
			throw new Error(
				i18n.t("missing-column-in-file", {
					column: missingHeadersInFile.join(", ").trim()
				})
			);
		}
		return true;
	}

	function csv2Array(csv, delimiter = ",") {
		const lines = parse(csv, {
			skipEmptyLines: true,
			delimiter
		}).data;

		const result = [];
		const headers = sanitizeHeader(lines[0]);

		for (let rowLine = 1; rowLine < lines.length; rowLine++) {
			if (lines[rowLine]) {
				const obj = {};
				const currentline = lines[rowLine];

				for (let column = 0; column < headers.length; column++) {
					const columnValue = currentline[column]?.trim();
					obj[headers[column]] = columnValue || "";
				}
				result.push(obj);
			}
		}
		return result;
	}

	const prepareCsvHeaderItem = (header) =>
		header.replace(/\s/g, "").toLowerCase().trim();

	function guessHeader(key, headers = allowedHeaders.value) {
		const fileHeaders = headers.map((header) =>
			prepareCsvHeaderItem(header)
		);
		const _key = key.toString().toLowerCase().replace(/\s/g, "").trim();
		const headerKeyMatch = findBestMatch(_key, fileHeaders);
		if (headerKeyMatch?.bestMatch?.rating >= 0.95) {
			return headers[headerKeyMatch.bestMatchIndex]?.trim();
		}

		return _key;
	}

	function sanitizeHeader(headerRow) {
		const sanitizedHeader = [];
		headerRow.forEach((header) => {
			sanitizedHeader.push(guessHeader(header));
		});

		return sanitizedHeader;
	}

	function validateCsvArray(
		list,
		requiredFileHeaders = requiredHeaders.value
	) {
		// normalize all header items
		const preparedRequiredCsvHeaders = requiredFileHeaders.map(
			(headerItem) => prepareCsvHeaderItem(headerItem)
		);
		let row = 1;
		for (const item of list) {
			for (const [key, value] of Object.entries(item)) {
				const requiredKeyPresent = preparedRequiredCsvHeaders.includes(
					// normalize key(header) item to compare against required headers
					prepareCsvHeaderItem(key)
				);

				if (requiredKeyPresent && value.toString().trim() === "") {
					throw new Error(`Missing ${key} on line ${row}`);
				}
			}
			row += 1;
		}

		return true;
	}

	function getDelimiterAndValidateHeader(file) {
		try {
			const delimiter = guessCsvHeaderDelimiter(file);
			if (!delimiter) {
				throw new Error(i18n.$t("wrong-file-header"));
			}

			if (validateCsvHeader(file, delimiter)) {
				return delimiter;
			}
			throw new Error(i18n.$t("wrong-file-header"));
		} catch (err) {
			throw new Error(err);
		}
	}

	function validateFileContent(file, delimiter) {
		const arr = csv2Array(file, delimiter);
		validateCsvArray(arr);
		return arr;
	}

	return {
		allowedHeaders,
		requiredHeaders,
		guessCsvHeaderDelimiter,
		validateCsvHeader,
		csv2Array,
		guessHeader,
		validateCsvArray,
		getDelimiterAndValidateHeader,
		validateFileContent
	};
}
