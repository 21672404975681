<template>
	<div
		:id="id || tableId"
		class="ws-data-table"
		:ref="tableId"
		data-testid="wsdatatable"
	>
		<ws-data-table-header
			v-if="!noTableHeader"
			:hide-search="hideSearch"
			:search-placeholder="searchPlaceholder || $t('search')"
			:filters="availableFilters"
			@search="searchTerm = $event"
			@selected-filter="selectedFilter"
			:stacked="stackedFilter"
		>
			<template
				#header-left
				v-if="$slots['header-left'] || showCollapseExpandButtons"
			>
				<slot name="header-left" />
				<!-- expand collapse buttons for groups-->
				<buttons-expand-collapse
					v-if="showCollapseExpandButtons && hasGroupedRows"
					class="mx-2 is-align-items-flex-end"
					@expanded-rows="
						expandAllGroups({
							resetPagination: true,
							applySort: false
						})
					"
					@collapsed-rows="collapseAllGroups"
					:collapse-tooltip="collapseButtonTooltip"
					:expand-tooltip="expandButtonTooltip"
				/>
			</template>

			<template #header-right v-if="$slots['header-right']">
				<slot name="header-right" />
			</template>
		</ws-data-table-header>
		<div
			v-if="$slots['above-table']"
			style="width: 100%; padding: 2rem auto"
		>
			<slot name="above-table" />
		</div>
		<div class="table-container">
			<table
				class="table is-fullwidth is-hoverable"
				:class="{ 'is-striped': isStriped }"
				:aria-describedby="`ws-datatable-${tableId}`"
			>
				<thead v-if="showHeader">
					<tr>
						<th
							v-for="(column, index) of columnObjects"
							:key="`ws_datatable_column_header_${index}_${column.selector}`"
							:id="`ws_datatable_column_header_${index}_${column.selector}`"
							data-testid="row-content"
							:style="
								column.type === 'checkbox' ? 'width: 35px' : ''
							"
						>
							<div
								class="is-inline-flex has-text-weight-medium"
								:class="{ 'is-clickable': column.sortable }"
								style="width: 100%"
								:data-testid="`header-column-${
									column.name || 'name'
								}-clickable`"
								@click="
									sortColumn(
										column.sortable ? column.selector : null
									)
								"
								v-tooltip="column.tooltip ?? null"
							>
								<slot
									:name="`column-${column.selector}-header`"
									:column="column"
								>
									<label
										v-if="column.type === 'checkbox'"
										class="checkbox"
									>
										<input
											data-testid="header-checkbox"
											type="checkbox"
											@change="
												toggleAllVisibleRowSelects(
													$event.target.checked
												)
											"
											:checked="headerCheckboxStatus"
											:disabled="
												disableHeaderCheckboxStatus
											"
										/>
									</label>
									<span v-else>
										{{ column.name }}
									</span>
								</slot>

								<template v-if="column.sortable">
									<span
										v-if="sort.column === column.selector"
										class="icon"
									>
										<ws-icon
											size="md"
											:icon="
												sort.order === 1
													? 'sort-up'
													: 'sort-down'
											"
										/>
									</span>
									<span v-else class="icon">
										<ws-icon size="md" icon="sort" />
									</span>
								</template>
							</div>
						</th>
						<th
							id="ws_datatable_column_header_expandable_rows"
							v-if="expandableRows"
						></th>
					</tr>
					<header-selected-properties
						v-if="selectedRows.length > 0"
						:visible-rows="visibleRows"
						:total-columns="totalColumns"
						:all-available-rows-are-selected="
							allAvailableRowsAreSelected
						"
						:is-data-filtered="isDataFiltered"
						:selected-rows="selectedRows.length"
						@apply-select-to-all-rows="applySelectToAllRows"
					>
						<template #bulk-action>
							<slot
								name="bulk-action"
								:selected-rows="selectedRows"
							/>
						</template>
					</header-selected-properties>
				</thead>
				<tbody>
					<tr v-if="paginatedRows.length === 0">
						<td :colspan="totalColumns">
							<slot name="message-no-data">
								<span>
									{{
										messageNoData ||
										$t(
											"ws-data-table.no-records-to-display"
										)
									}}
								</span>
							</slot>
						</td>
					</tr>
					<template v-if="hasGroupedRows">
						<template
							v-for="group in paginatedGroupedRows"
							:key="`ws_datatable_row_group_${
								group.groupId || group.groupName
							}`"
						>
							<tr
								class="tr-group-title"
								:data-testid="`group-row-${group.groupId}`"
								@click="toggleCollapseGroup(group.groupId)"
								:style="selectable ? 'width: 50px' : ''"
							>
								<td v-if="selectable">
									<span>
										<label class="checkbox">
											<input
												data-testid="group-row-checkbox"
												type="checkbox"
												:checked="
													isGroupRowsChecked(
														group.groupId
													)
												"
												@click.stop="
													toggleGroupCheckbox(
														group.groupId,
														$event.target.checked
													)
												"
											/>
										</label>
									</span>
								</td>
								<!-- group  -->
								<td
									:colspan="
										totalColumns - (selectable ? 1 : 0)
									"
									data-testid="row-content"
								>
									<div
										class="is-flex is-justify-content-space-between"
									>
										<div>
											<div
												class="is-flex is-align-items-center"
											>
												{{
													getTitleGroupName(
														group.groupName
													)
												}}

												<span
													v-if="showTotalItemsInGroup"
												>
													({{
														getTitleGroupName(
															group.groupRowsCount
														)
													}})
												</span>

												<ws-icon
													icon="caret-right"
													:rotate="
														!isGroupCollapsed(
															group.groupId
														)
															? '90'
															: '0'
													"
													size="md"
												/>
											</div>

											<!-- GROUP ROW Description -->
											<span
												v-if="group.groupDescription"
												class="help has-text-grey-500 has-text-weight-normal"
											>
												{{ group.groupDescription }}
											</span>
										</div>
										<slot
											v-if="hasGroupActions"
											name="groupActions"
											:group="group"
										>
										</slot>
									</div>
								</td>
							</tr>
							<template v-if="!isGroupCollapsed(group.groupName)">
								<template
									v-for="(row, index) of group.rows"
									:key="`ws_datatable_row_${
										group.groupName
									}_${row.id || index}`"
								>
									<tr
										:class="{
											'is-clickable':
												checkIfRowIsClickable(row),
											'row-selected':
												checkIfRowIsSelected(row)
										}"
										:style="checkIfRowIsHighlighted(row)"
										:data-testid="`row-content row-content-${
											row.id || index
										}`"
										@click.left.capture="
											clickedRow(row, 'left', $event)
										"
										@click.capture.middle="
											clickedRow(row, 'middle', $event)
										"
									>
										<row
											v-for="column of columnObjects"
											:key="`ws_datatable_row_td_${column.selector}`"
											:column="column"
											:td-class="tdClass"
										>
											<slot
												:name="column.selector"
												:row="row"
												:index="
													Number(
														`${paginator.page - 1}${index}`
													)
												"
												:is-expanded="
													isRowExpanded(
														row.id || index
													)
												"
												:toggle-checkbox="
													toggleSelectRowCheckbox
												"
											>
												<row-content
													:column="column"
													:row="row"
													@change="
														toggleSelectRowCheckbox(
															row,
															$event
														)
													"
												/>
											</slot>
										</row>
										<td
											v-if="expandableRows"
											class="expand-toggle-button"
										>
											<div
												data-testid="expand-toggle-button"
												@click="
													toggleExpandRow(
														row.id || index
													)
												"
											>
												<slot
													name="expandableRowIcon"
													:is-expanded="
														isRowExpanded(
															row.id || index
														)
													"
													:row="row"
												>
													<ws-icon
														icon="caret-right"
														:rotate="
															isRowExpanded(
																row.id || index
															)
																? '90'
																: '0'
														"
														size="md"
													/>
												</slot>
											</div>
										</td>
									</tr>
									<tr
										v-if="
											expandableRows &&
											isRowExpanded(row.id || index)
										"
										:key="`ws_datatable_expandable_row_${
											row.id || index
										}`"
									>
										<td :colspan="totalColumns">
											<slot
												name="expandableRow"
												:row="row"
											/>
										</td>
									</tr>
								</template>
							</template>
						</template>
					</template>
					<template v-else>
						<template
							v-for="(row, index) in paginatedRows"
							:key="`ws_datatable_row_${row.id || index}`"
						>
							<tr
								:class="{
									'is-clickable': checkIfRowIsClickable(row),
									'row-selected': checkIfRowIsSelected(row)
								}"
								:style="checkIfRowIsHighlighted(row)"
								:data-testid="`row-content row-content-${
									row.id || index
								}`"
								@click.left="clickedRow(row, 'left', $event)"
								@click.capture.middle="
									clickedRow(row, 'middle', $event)
								"
							>
								<row
									v-for="column of columnObjects"
									:key="`ws_datatable_row_td_${column.selector}`"
									:column="column"
									:td-class="tdClass"
								>
									<slot
										:name="column.selector"
										:row="row"
										:index="
											Number(
												`${paginator.page - 1}${index}`
											)
										"
										:toggle-checkbox="
											toggleSelectRowCheckbox
										"
									>
										<row-content
											:key="`ws_datatable_row_item_${
												row.id || index
											}`"
											:column="column"
											:row="row"
											@change="
												toggleSelectRowCheckbox(
													row,
													$event
												)
											"
										/>
									</slot>
								</row>
								<td
									v-if="expandableRows"
									class="expand-toggle-button"
								>
									<div
										data-testid="expand-toggle-button"
										@click="
											toggleExpandRow(row.id || index)
										"
									>
										<slot
											name="expandableRowIcon"
											:is-expanded="
												isRowExpanded(row.id || index)
											"
											:row="row"
										>
											<ws-icon
												icon="caret-right"
												:rotate="
													isRowExpanded(
														row.id || index
													)
														? '90'
														: '0'
												"
												size="md"
											/>
										</slot>
									</div>
								</td>
							</tr>
							<tr
								v-if="
									expandableRows &&
									isRowExpanded(row.id || index)
								"
								:key="`ws_datatable_expandable_row_${
									row.id || index
								}`"
							>
								<td :colspan="totalColumns">
									<slot name="expandableRow" :row="row" />
								</td>
							</tr>
						</template>
					</template>
				</tbody>
			</table>
		</div>
		<paginator
			:is-server-pagination="serverUrl !== null"
			:server-pagination-has-next-page="serverPaginationHasNextPage"
			:page="paginator.page"
			:total-pages="totalPages"
			@changed-page="changedPage"
		/>
	</div>
</template>

<script>
import {
	createColumnObject,
	generateRandomId,
	naturalSorting,
	prepareRowsForDataTable,
	clearCacheRows,
	WS_DATATABLE_INDEX,
	WS_DATATABLE_SELECT,
	createCollapsedGroupsStorageName,
	WS_DATATABLE_GROUP,
	WS_IGNORE_SELECT
} from "./helpers.js";
import Paginator from "./components/paginator.vue";
import Header from "./components/header.vue";
import RowContent from "./components/row-content.vue";
import Row from "./components/row.vue";
import globalHelpers, {
	normalizeString,
	isNumber
} from "@/helpers/functions.helper.js";
import axios from "axios";
import EventBus from "@/eventbus.js";
import ButtonsExpandCollapse from "./components/buttons-expand-collapse.vue";
import HeaderSelectedProperties from "./components/header-selected-properties.vue";

const WS_DATATABLE_GROUP_NAME = "ws_datatable_group_name";
const WS_DATATABLE_GROUP_DESCRIPTION = "ws_datatable_group_description";
const WS_DATATABLE_NON_GROUPED = "ws_datatable_non_grouped";
const WS_NON_GROUPED_LABEL = "Non grouped";

export default {
	name: "WsDataTable",

	emits: [
		"update:modelValue",
		"startedServerRequest",
		"failedServerRequest",
		"finishedServerRequest",
		"rowClick"
	],

	components: {
		Paginator,
		"ws-data-table-header": Header,
		RowContent,
		Row,
		ButtonsExpandCollapse,
		HeaderSelectedProperties
	},

	props: {
		id: {
			type: String,
			default: null
		},
		/**
		 * Allow v-model in ws-data-table
		 * Used only when selectable = true
		 */
		modelValue: {
			type: Array,
			default: () => []
		},
		/**
		 * Special keys:
		 * - WS_DATATABLE_GROUP: If identified, it will create groups in the datatable
		 * - WS_DATATABLE_GROUP_NAME: If identified, it will be the group display name in the datatable
		 * - WS_IGNORE_SELECT: This will ignore the row from datatable select feature (checkbox)
		 */
		rows: {
			type: Array,
			default: () => []
		},

		/*
		{ name, selector*, sortable, hide, width, filter, tooltip} | *mandatory
		['id', 'name', 'description', 'obs', 'actions']
		[
			{ name: 'ID', selector: 'id', sortable: true, width: '5%' },
			{ name: 'Name', selector: 'name', sortable: true, filter: true },
			{ name: 'Description', selector: 'desc', sortable: false },
			{ name: '', selector: 'obs', sortable: false, hide: true },
			{ name: '', selector: 'actions', sortable: false }
		]
		*/
		columns: {
			type: Array,
			default: () => [],
			validator: function (value) {
				if (!(value instanceof Array)) {
					return false;
				}
				for (const item in value) {
					if (typeof item === "string") {
						continue;
					}
					if (!(item instanceof Object)) {
						return false;
					}
					const keys = Object.keys(item);
					if (keys.indexOf("selector") !== -1) {
						return false;
					}
				}

				return true;
			}
		},

		clickableRow: {
			type: [Boolean, Function],
			default: false
		},

		enableMouseMiddleButtonClick: {
			type: Boolean,
			default: false
		},

		isStriped: {
			type: Boolean,
			default: false
		},

		/**
		 * External filter
		 * @param {array of object} externalFilters Array of objects [{selector: string, value: string}, ...]
		 */
		externalFilters: {
			type: Array,
			default: () => [],
			validator: function (value) {
				if (!(value instanceof Array)) {
					return false;
				}
				for (const item of value) {
					if (!(item instanceof Object)) {
						return false;
					}
					const keys = Object.keys(item);
					if (!keys.includes("selector") || !keys.includes("value")) {
						return false;
					}
				}

				return true;
			}
		},

		externalSearchTerm: {
			type: String,
			default: null
		},

		noTableHeader: {
			type: Boolean,
			default: false
		},
		hideSearch: {
			type: Boolean,
			default: false
		},

		searchPlaceholder: {
			type: String,
			default: null
		},

		itemsPerPage: {
			type: Number,
			default: 50
		},

		messageNoData: {
			type: String,
			default: null
		},

		showGroupsInFilter: {
			type: Boolean,
			default: false
		},

		groupsFilterLabel: {
			type: String,
			default: null
		},

		// active checkbox selection
		selectable: {
			type: Boolean,
			default: false
		},

		selectableMessage: {
			type: Function,
			default: null
		},

		// expandable rows configuration
		expandableRows: {
			type: Boolean,
			default: false
		},

		// start with groups collapsed?
		startWithCollapsedGroups: {
			type: Boolean,
			default: false
		},
		// save user's collapsed/expanded groups so when he returns to that page, it automatically expand all groups to him
		keepCollapsedGroups: {
			type: Boolean,
			default: true
		},

		defaultSort: {
			type: Object,
			default: null,
			validator: function (value) {
				if (!(value instanceof Object)) {
					return false;
				}
				const keys = Object.keys(value);
				if (!keys.includes("column") || !keys.includes("order")) {
					return false;
				}

				return true;
			}
		},

		// server configuration
		/** URL it will be used to request to server */
		serverUrl: {
			type: String,
			default: null
		},
		/** query param related to page */
		serverPageParam: {
			type: String,
			default: "page"
		},
		/** query param related to items-per-page */
		serverItemsPerPageParam: {
			type: String,
			default: "size"
		},
		/** other query params to ask server */
		serverQueryParams: {
			type: Object,
			default: () => {
				return {};
			}
		},
		serverGetDataFromResponse: {
			type: Function,
			default: function (data) {
				return data;
			}
		},
		serverTransformResponse: {
			type: Function,
			default: null
		},
		// used to add action buttons inside the group header
		hasGroupActions: {
			type: Boolean,
			default: false
		},

		showHeader: {
			type: Boolean,
			default: true
		},
		stackedFilter: {
			type: Boolean,
			default: false
		},
		tdClass: {
			type: String,
			default: ""
		},
		highlightRow: {
			type: Function,
			default: null
		},
		showTotalItemsInGroup: {
			type: Boolean,
			default: false
		},
		showCollapseExpandButtons: {
			type: Boolean,
			default: true
		},
		collapseButtonTooltip: {
			type: String,
			default: null
		},
		expandButtonTooltip: {
			type: String,
			default: null
		}
	},

	watch: {
		rows: {
			handler: function (_newRows, _oldRows) {
				const { rows, filters } = prepareRowsForDataTable(
					[..._newRows],
					this.visibleRows,
					this.columns,
					this.sort
				);

				this.datatableRows = rows;

				if (_oldRows?.length === 0 && _newRows?.length > 0) {
					const userHasInteractedWithTable =
						!!this.getCollapsedGroupsInStorage();
					if (
						this.keepCollapsedGroups &&
						userHasInteractedWithTable
					) {
						this.applyCollapsedGroupsFromStorage();
					} else if (this.startWithCollapsedGroups) {
						this.collapseAllGroups();
					}
				}

				this.applyFilterAndSearch(false, false, false);
				this.availableFilters = filters;
				if (
					!this.serverUrl &&
					this.datatableRows.length <= this.itemsPerPage &&
					this.paginator?.page > 1
				) {
					this.paginator.page = 1;
				}
			},
			deep: true
		},

		externalSearchTerm: function (_newTerm) {
			this.searchTerm = _newTerm;
		},

		/**
		 * externalFilters {array} [{selector: String, value: String|Number}, {}, ...]
		 */
		externalFilters: {
			handler: function (_newFilters, _oldFilters) {
				for (const filter of _newFilters) {
					/**
					 * Check if filter exists in _oldFilters and if it's different
					 * This was implemented to make sure when startWithCollapsedGroups is true, to guarantee all groups as collapsed, because on applyFilterAndSearch() method, it resets the collapsedGroups
					 */
					const sameInOldFilters = _oldFilters.find(
						(_f) =>
							_f.selector === filter.selector &&
							_f.value === filter.value
					);
					if (sameInOldFilters) {
						continue;
					}

					if (filter.value === "") {
						filter.value = null;
					}

					this.selectedFilter(filter);
				}
			},
			deep: true
		},

		searchTerm: function () {
			this.applyFilterAndSearch();
		},

		serverQueryParams: function () {
			if (this.serverUrl) {
				this.getRowsFromServer(true);
			}
		},

		itemsPerPage: function () {
			if (this.serverUrl) {
				this.getRowsFromServer(true);
			}
		}
	},

	data() {
		return {
			tableId: generateRandomId(),
			serverRows: [],
			serverPaginationHasNextPage: true,
			searchTerm: this.externalSearchTerm,
			visibleRows: [], // used to display
			datatableRows: [], // state of all rows. it has row_id and row_select. It's changed only when rows/serverRows are changed (reset) and when a row is selected/unselected
			paginator: {
				page: 1
			},
			sort: this.defaultSort || {
				column: null,
				order: 1
			},
			appliedFilters: {},
			collapsedGroups: [],
			collapsedGroupsStorageKeyName: createCollapsedGroupsStorageName(
				this.id
			), // key name used to save in storage groups to automatically expand/collapse when user arrives in the page that has this data-table
			expandedRows: [],
			filters: [],

			displayMessageSelectAllRows: false // true when user click on header-checkbox to select all rows from a page; false when uncheck any checkbox than header-checkbox
		};
	},

	created() {
		const { rows, filters } = prepareRowsForDataTable(
			[...this.rows],
			undefined,
			this.columns
		);
		this.availableFilters = filters;
		this.visibleRows = rows;
		this.datatableRows = rows;
		EventBus.$on("ws-data-table-uncheck-all", this.uncheckAll);
	},

	mounted() {
		if (this.serverUrl !== null) {
			this.getRowsFromServer();
		}

		if (this.datatableRows?.length > 0) {
			// if keepCollapsedGroups is true (and user has already had any interaction with that table - that's important), we'll use it. If not, then we inspect the startWithCollapsedGroups prop. -> what I'm thinking here is that, keepCollapsedGroups has more priority than startWithCollapsedGroups because user has already interacted with that table.
			const userHasInteractedWithTable =
				!!this.getCollapsedGroupsInStorage(); // if keepCollapsedGroups = true && user already interacted with table, the method will return an array, so it will be true this variable (even if array is empty). If user didn't interact with this table yet, there's no point asking to use the saved collapsed groups, because there won't be any
			if (this.keepCollapsedGroups && userHasInteractedWithTable) {
				this.applyCollapsedGroupsFromStorage();
			} else if (this.startWithCollapsedGroups) {
				this.collapseAllGroups();
			}
		}

		if (this.defaultSort) {
			this.sortColumn(this.defaultSort.column, this.defaultSort.order);
		}
	},
	unmounted() {
		clearCacheRows();
		EventBus.$off("ws-data-table-uncheck-all", this.uncheckAll);

		if (this.keepCollapsedGroups) {
			this.saveCollapsedGroupsInStorage();
		}
	},

	methods: {
		getCollapsedGroupsInStorage() {
			return JSON.parse(
				sessionStorage.getItem(this.collapsedGroupsStorageKeyName)
			);
		},

		saveCollapsedGroupsInStorage() {
			if (this.keepCollapsedGroups) {
				sessionStorage.setItem(
					this.collapsedGroupsStorageKeyName,
					JSON.stringify(this.collapsedGroups)
				);
			}
		},

		applyCollapsedGroupsFromStorage() {
			// as we store all the collapsed groups, it's as simple as getting the stored list and applying to the this.collapsedGroups
			const storedCollapsedGroups = this.getCollapsedGroupsInStorage();
			if (storedCollapsedGroups) {
				this.collapsedGroups = [...storedCollapsedGroups];
			}
		},

		getTitleGroupName(value) {
			if (value === WS_DATATABLE_NON_GROUPED) {
				return WS_NON_GROUPED_LABEL;
			}
			return value;
		},

		uncheckAll() {
			this.datatableRows = [
				...this.datatableRows.map((row) => {
					return {
						...row,
						[WS_DATATABLE_SELECT]: row[WS_IGNORE_SELECT]
							? row[WS_DATATABLE_SELECT]
							: false
					};
				})
			];

			// update visibleRows
			this.visibleRows = [
				...this.visibleRows.map((_row) => {
					return {
						..._row,
						[WS_DATATABLE_SELECT]: _row[WS_IGNORE_SELECT]
							? _row[WS_DATATABLE_SELECT]
							: false
					};
				})
			];

			this.$emit("update:modelValue", this.selectedRows);
		},

		collapseAllGroups() {
			this.collapsedGroups = [
				...new Set([
					...this.datatableRows
						.flatMap((row) => row[WS_DATATABLE_GROUP])
						.filter(Boolean),
					WS_DATATABLE_NON_GROUPED
				])
			];

			// as we're saving the collapsed groups, whenever all groups are collapsed
			this.saveCollapsedGroupsInStorage();
			this.paginator.page = 1;
		},

		expandAllGroups({ resetPagination = true, applySort = false }) {
			this.collapsedGroups = [];
			this.applyFilterAndSearch(resetPagination, applySort, false);
			this.saveCollapsedGroupsInStorage();
		},

		isRowExpanded(rowId) {
			return this.expandedRows.includes(rowId);
		},

		toggleExpandRow(rowId) {
			if (this.isRowExpanded(rowId)) {
				return (this.expandedRows = this.expandedRows.filter(
					(r) => r !== rowId
				));
			}

			this.expandedRows = [...this.expandedRows, rowId];
			return this.expandedRows;
		},

		toggleAllVisibleRowSelects(value) {
			for (const row of this.paginatedRows) {
				if (row[WS_IGNORE_SELECT]) {
					continue;
				}
				row[WS_DATATABLE_SELECT] = value;
				const datatableRow = this.datatableRows.find(
					(_row) =>
						_row[WS_DATATABLE_INDEX] === row[WS_DATATABLE_INDEX]
				);
				datatableRow[WS_DATATABLE_SELECT] = value;
			}

			this.displayMessageSelectAllRows = value;

			this.$emit("update:modelValue", this.selectedRows);
		},

		toggleSelectRowCheckbox(row, value) {
			const index = row[WS_DATATABLE_INDEX];
			/**
			 * It's necessary to update visibleRows and datatableRows because <row-content> component is functional so it will only be updated with a reactivity update from those 2 arrays
			 */
			// update datatableRows
			const rowFromDatatable = this.datatableRows.find(
				(_row) => _row[WS_DATATABLE_INDEX] === index
			);
			rowFromDatatable[WS_DATATABLE_SELECT] = value;

			// update visibleRows
			const rowIndexFromAllRows = this.visibleRows.findIndex(
				(_row) => _row[WS_DATATABLE_INDEX] === index
			);

			this.visibleRows.splice(rowIndexFromAllRows, 1, {
				...this.visibleRows[rowIndexFromAllRows],
				[WS_DATATABLE_SELECT]: value
			});

			this.displayMessageSelectAllRows = false;

			this.$emit("update:modelValue", this.selectedRows);
		},

		applySelectToAllRows(value) {
			for (const row of this.visibleRows) {
				if (row[WS_IGNORE_SELECT]) {
					continue;
				}
				row[WS_DATATABLE_SELECT] = value;

				const rowDatatableRows = this.datatableRows.find(
					(dtRow) =>
						dtRow[WS_DATATABLE_INDEX] === row[WS_DATATABLE_INDEX]
				);
				if (rowDatatableRows) {
					rowDatatableRows[WS_DATATABLE_SELECT] = value;
				}
			}

			this.$emit("update:modelValue", this.selectedRows);
		},

		toggleGroupCheckbox(groupName, value) {
			const datatableRows = this.rowsFromGroup(groupName);

			for (const row of datatableRows) {
				if (row[WS_IGNORE_SELECT]) {
					continue;
				}
				row[WS_DATATABLE_SELECT] = value;

				const rowVisibleRows = this.visibleRows.find(
					(dtRow) =>
						dtRow[WS_DATATABLE_INDEX] === row[WS_DATATABLE_INDEX]
				);
				if (rowVisibleRows) {
					rowVisibleRows[WS_DATATABLE_SELECT] = value;
				}
			}

			this.$emit("update:modelValue", this.selectedRows);
		},

		isGroupRowsChecked(groupName) {
			return (
				this.rowsFromGroup(groupName).filter(
					(_row) => _row[WS_DATATABLE_SELECT] === true
				).length > 0
			);
		},

		rowsFromGroup(groupName) {
			return this.datatableRows.filter(
				(_row) =>
					// when no group, it will be undefined, so assign the WS_DATATABLE_NON_GROUPED variable to it
					(_row[WS_DATATABLE_GROUP] || WS_DATATABLE_NON_GROUPED) ===
					groupName
			);
		},

		isGroupCollapsed(group) {
			return this.collapsedGroups.includes(group);
		},

		toggleCollapseGroup(group) {
			if (this.isGroupCollapsed(group)) {
				this.collapsedGroups = this.collapsedGroups.filter(
					(_group) => _group !== group
				);
				this.saveCollapsedGroupsInStorage();
				return this.collapsedGroups;
			}

			this.collapsedGroups = [...this.collapsedGroups, group];
			this.saveCollapsedGroupsInStorage();

			if (this.paginator.page > this.totalPages) {
				this.paginator.page = this.totalPages || 1;
			}

			return this.collapsedGroups;
		},

		async getRowsFromServer(resetPagination = false) {
			try {
				this.$emit("startedServerRequest");
				if (resetPagination) {
					return this.changedPage(1);
				}
				const queryParams = {
					[this.serverItemsPerPageParam]: this.itemsPerPage || 50,
					[this.serverPageParam]: this.paginator.page || 1,
					...this.serverQueryParams
				};
				const _data = await axios.get(this.serverUrl, {
					params: queryParams,
					transformResponse: [
						(data) => {
							if (!data) {
								return [];
							}
							if (this.serverTransformResponse !== null) {
								return this.serverTransformResponse(data);
							}
							return JSON.parse(data);
						}
					]
				});
				this.serverRows = this.serverGetDataFromResponse(_data?.data);
				this.serverPaginationHasNextPage =
					this.serverRows.length === this.itemsPerPage;
				const { rows, filters } = prepareRowsForDataTable([
					...this.serverRows
				]);
				this.datatableRows = rows;
				this.applyFilterAndSearch(resetPagination);
				this.availableFilters = filters;
			} catch (err) {
				this.$toasted.error(
					`${err.message || ""} | Failed loading content from server`
				);
				this.$emit("failedServerRequest", err);
				// throw new Error(err.message);
			} finally {
				this.$emit("finishedServerRequest");
			}
		},

		changedPage(newPage) {
			// keep collapsed group when changing navigation page
			// this.collapsedGroups = [];
			this.expandedRows = [];
			const wsView = document.querySelector(".ws-data-table");
			if (wsView) {
				wsView.scrollIntoView(true);
			} else {
				this.$refs[this.tableId].scrollIntoView(true);
			}
			this.paginator.page = newPage;
			this.displayMessageSelectAllRows = false;

			if (this.serverUrl) {
				return this.getRowsFromServer();
			}
		},

		selectedFilter({ value, selector, collapseAllGroups = false }) {
			this.appliedFilters[selector] = value;
			this.appliedFilters =
				globalHelpers.removeNullParamsFromObject(this.appliedFilters) ||
				{};
			this.applyFilterAndSearch(true, true, !collapseAllGroups);
		},

		applyFilterAndSearch(
			resetPagination = true,
			applySort = true,
			expandCollapsedGroups = true
		) {
			if (expandCollapsedGroups) {
				this.collapsedGroups = [];
			}
			let rows = [...this.datatableRows];
			rows = this.applyFilters(rows);
			rows = this.applySearch(rows);
			if (resetPagination) {
				this.paginator.page = 1;
			}
			this.visibleRows = rows;
			if (applySort) {
				this.sortColumn(this.sort.column, this.sort.order);
			}
		},

		applyFilters(rows) {
			const filterSelectors = Object.keys(this.appliedFilters);
			if (filterSelectors.length === 0) {
				return rows;
			}

			return rows.filter((row) => {
				for (const selector of filterSelectors) {
					if (row[selector] != this.appliedFilters[selector]) {
						return false;
					}
				}

				return true;
			});
		},

		applySearch(rows) {
			function prepareString(str) {
				return normalizeString(str.toString().toLowerCase().trim());
			}

			if (!this.searchTerm || prepareString(this.searchTerm) === "") {
				return rows;
			}

			const searchTerms = prepareString(this.searchTerm).split(" ");
			return rows.filter((row) => {
				const foundTerms = searchTerms.filter((searchTerm) => {
					for (const selector of this.columnSelectors) {
						if (
							typeof row[selector] === "number" ||
							typeof row[selector] === "string"
						) {
							const rowValue = prepareString(row[selector]);
							if (rowValue.includes(searchTerm)) {
								return true;
							}
						}
					}
					return false;
				});

				return foundTerms.length === searchTerms.length;
			});
		},

		/**
		 * Sort column
		 * @param {string} rowSelector column to be ordered by its selector name
		 * @param {string} order force column to order by ASC or DESC
		 */
		sortColumn(rowSelector, order = null) {
			if (rowSelector === null) {
				return;
			}
			// if specified the order as a string, convert it to number
			let sortOrder = null;
			if (order) {
				if (!isNumber(order)) {
					sortOrder = order.toLowerCase() === "asc" ? 1 : -1;
				} else {
					sortOrder = order;
				}
			}

			if (this.sort.column == rowSelector) {
				this.sort.order = sortOrder || this.sort.order * -1;
			} else {
				this.sort.column = rowSelector;
				this.sort.order = sortOrder || 1;
			}

			this.visibleRows.sort((a, b) => {
				const elemA = a[this.sort.column] || "";
				const elemB = b[this.sort.column] || "";
				return naturalSorting(elemA, elemB) * this.sort.order;
			});

			// If fixed sort column
			// example: to keep groups order
			if (this.sort?.defaultColumn) {
				this.visibleRows.sort((a, b) => {
					const elemA = a[this.sort.defaultColumn] || "";
					const elemB = b[this.sort.defaultColumn] || "";
					return naturalSorting(elemA, elemB) * 1;
				});
			}
		},

		clickedRow(row, mouseClickButton, event) {
			if (mouseClickButton === "middle") {
				if (!this.enableMouseMiddleButtonClick) {
					return;
				}
			}

			if (this.checkIfRowIsClickable(row)) {
				if (mouseClickButton === "middle") {
					event.preventDefault();
				}
				this.$emit("rowClick", row, mouseClickButton);
			}
		},

		checkIfRowIsClickable(row) {
			if (typeof this.clickableRow === "boolean") {
				return this.clickableRow;
			}
			return this.clickableRow(row);
		},

		checkIfRowIsSelected(row) {
			if (this.highlightRow !== null) {
				// if it's not a boolean, it will be applied as a style
				const highlightRow = this.checkIfRowIsHighlighted(row);
				if (typeof highlightRow === "boolean") {
					return highlightRow;
				}
			}

			return row[WS_DATATABLE_SELECT] && !row[WS_IGNORE_SELECT];
		},

		checkIfRowIsHighlighted(row) {
			if (this.highlightRow !== null) {
				const highlightRow = this.highlightRow(row);
				if (highlightRow !== null) {
					// if it's a string, it will be considered as being a style, so add !important after each statement to make sure style will be taken into account
					if (typeof highlightRow === "string") {
						return highlightRow
							.split(";")
							.map((style) => `${style} !important`)
							.join(";");
					}

					return highlightRow;
				}
			}

			return null;
		}
	},

	computed: {
		availableFilters: {
			get() {
				return this.filters;
			},
			set(value) {
				if (this.showGroupsInFilter) {
					const groupFilterIdx = value.findIndex(
						(v) => v.selector === WS_DATATABLE_GROUP
					);
					if (groupFilterIdx !== -1) {
						value[groupFilterIdx].name =
							this.groupsFilterLabel || this.$t("group");
					}
					this.filters = value;
				} else {
					const filtersWithoutGroup = value.filter(
						(v) => v.selector !== WS_DATATABLE_GROUP
					);
					this.filters = filtersWithoutGroup;
				}
			}
		},
		/**
		 * @output [{}, {}, ...]
		 */
		paginatedRows() {
			if (this.serverUrl) {
				return this.visibleRows;
			}

			const start = (this.paginator.page - 1) * this.itemsPerPage;
			const end = start + this.itemsPerPage;
			return this.visibleRows.slice(start, end);
		},

		/**
		 * @output [ {groupName:"",groupId: "",  rows: [{}, {}] },
		 * { groupName:"otherName",groupId: "",  rows: [{}, {}] } ]
		 */
		paginatedGroupedRows() {
			let groupDisplayNames = {};
			let groupDescriptionNames = {};
			let groupedRows = this.visibleRows.reduce((acc, curr) => {
				if (!curr) {
					return acc;
				}
				// get group that row belongs to. if not provided, assign to custom group name
				const groupName =
					curr[WS_DATATABLE_GROUP] || WS_DATATABLE_NON_GROUPED;
				const groupDescription =
					curr[WS_DATATABLE_GROUP_DESCRIPTION] || "";

				// return accumulator with row added to group name
				groupDisplayNames[groupName] =
					curr[WS_DATATABLE_GROUP_NAME] ||
					curr[WS_DATATABLE_GROUP] ||
					WS_DATATABLE_NON_GROUPED;

				groupDescriptionNames[groupName] = groupDescription;
				return {
					...acc,
					// if groupName doesn't exist, spread an empty array
					[groupName]: [...(acc[groupName] || []), curr]
				};
			}, {});

			//count the Rows by group
			let groupRowsCount = {};
			for (const groupItem in groupedRows) {
				groupRowsCount[groupItem] = groupedRows[groupItem].length;
			}

			// move non-grouped rows to the end of object;
			const non_grouped_rows = groupedRows[WS_DATATABLE_NON_GROUPED];
			if (non_grouped_rows) {
				delete groupedRows[WS_DATATABLE_NON_GROUPED];
				groupedRows = {
					...groupedRows,
					[WS_DATATABLE_NON_GROUPED]: non_grouped_rows
				};
			}

			const startIndex = (this.paginator.page - 1) * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;
			const totalRows = endIndex - startIndex;

			// groupedRows = { groupName: [{}, {}], otherName: [...], differentGroupName: [...]}
			let finalRows = {};
			let index = 0;
			let totalRowsAdded = 0;

			for (const group in groupedRows) {
				// if end of page & last item in a expanded group with more items
				if (
					totalRowsAdded >= totalRows &&
					!this.isGroupCollapsed(group)
				) {
					break;
				}

				// if group is collapsed, show only its name, but don't add the rows
				if (this.isGroupCollapsed(group)) {
					// add the collapsed group if in the middle of the page
					// in the beginig of the page if not already have rows
					// || in the middle of the page
					// || in the end if it's the last things to add
					if (
						!finalRows[group] &&
						((totalRowsAdded === 0 && this.paginator.page === 1) ||
							(index >= startIndex &&
								totalRowsAdded < totalRows) ||
							(this.paginator.page === this.totalPages &&
								totalRowsAdded !== 0))
					) {
						finalRows[group] = [];
					}
				} else {
					for (const row of groupedRows[group]) {
						if (index >= startIndex) {
							if (!finalRows[group]) {
								finalRows[group] = [];
							}
							if (totalRowsAdded < totalRows) {
								finalRows[group] = [...finalRows[group], row];
								totalRowsAdded++;
							}
						} else {
							// if (totalRowsAdded >= totalRows) {
							// 	break;
							// }
						}

						index++;
					}
				}
			}

			// { groupName: [{}, {}], otherName: [...], differentGroupName: [...]}
			// =>
			// [ { groupName: "", groupId: "", rows: [{}, {}] },
			// { groupName: "otherName",groupId: "", rows: [{}, {}] } ]
			let finalGroups = Object.keys(finalRows).map((_groupId) => {
				return {
					groupName: groupDisplayNames[_groupId] || _groupId,
					groupId: _groupId,
					groupDescription: groupDescriptionNames[_groupId],
					rows: finalRows[_groupId],
					groupRowsCount: groupRowsCount[_groupId]
				};
			});

			/* 	When rows are sorted by name, and only by name,
			 *	group order is sort too and take advantage to rows order
			 */
			if (this.sort.column === "name") {
				finalGroups = finalGroups.sort((a, b) => {
					const elemA = a.groupName || "";
					const elemB = b.groupName || "";
					return naturalSorting(elemA, elemB) * this.sort.order;
				});
			}

			return finalGroups;
		},

		totalPages() {
			if (this.hasGroupedRows) {
				const nonCollapsedRows = this.visibleRows.filter((row) => {
					const groupName =
						row[WS_DATATABLE_GROUP] || WS_DATATABLE_NON_GROUPED;

					return !this.isGroupCollapsed(groupName);
				});

				return Math.ceil(nonCollapsedRows.length / this.itemsPerPage);
			} else {
				return Math.ceil(this.visibleRows.length / this.itemsPerPage);
			}
		},

		columnObjects() {
			const finalColumns = [];
			if (this.columns && this.columns.length > 0) {
				if (this.selectable) {
					finalColumns.push(createColumnObject({ type: "checkbox" }));
				}
				for (const column of this.columns) {
					if (column.hide) {
						continue;
					}
					if (typeof column === "string") {
						finalColumns.push(createColumnObject({ name: column }));
					} else {
						finalColumns.push(column);
					}
				}

				return finalColumns;
			}

			if (this.visibleRows && this.visibleRows.length > 0) {
				if (this.selectable) {
					finalColumns.push(createColumnObject({ type: "checkbox" }));
				}
				Object.keys(this.visibleRows[0]).forEach((key) => {
					finalColumns.push(createColumnObject({ name: key }));
				});
			}

			return finalColumns;
		},

		columnSelectors() {
			return this.columns.map((column) => column.selector);
		},

		hasGroupedRows() {
			return !!this.visibleRows.find(
				(row) =>
					row &&
					row[WS_DATATABLE_GROUP] &&
					row[WS_DATATABLE_GROUP] !== ""
			);
		},

		selectedRows() {
			return this.datatableRows.filter(
				(row) => row[WS_DATATABLE_SELECT] && !row[WS_IGNORE_SELECT]
			);
		},

		totalAvailableRowsToSelect() {
			return this.datatableRows.filter((row) => !row[WS_IGNORE_SELECT])
				.length;
		},

		allAvailableRowsAreSelected() {
			return this.selectedRows.length === this.totalAvailableRowsToSelect;
		},

		headerCheckboxStatus() {
			if (this.selectedRows.length === 0) {
				return false;
			}
			if (this.allAvailableRowsAreSelected) {
				return true;
			}

			const selectedRowsIndexes = this.selectedRows.map(
				(prop) => prop[WS_DATATABLE_INDEX]
			);
			let visibleProperties = [];
			// same logic applied for displaying rows in table (if it's grouped or not)
			if (this.hasGroupedRows) {
				visibleProperties = this.paginatedGroupedRows
					.map((group) => group.rows)
					.flatMap((prop) => prop);
			} else {
				visibleProperties = this.paginatedRows;
			}

			// check if any of the visible properties are in the selectedRows
			for (const prop of visibleProperties) {
				if (selectedRowsIndexes.includes(prop[WS_DATATABLE_INDEX])) {
					return true;
				}
			}

			return false;
		},

		disableHeaderCheckboxStatus() {
			let visibleProperties = [];
			if (this.hasGroupedRows) {
				visibleProperties = this.paginatedGroupedRows
					.map((group) => group.rows)
					.flatMap((prop) => prop);
			} else {
				visibleProperties = this.paginatedRows;
			}

			const totalUnselectableRows = visibleProperties.filter(
				(prop) => prop[WS_IGNORE_SELECT]
			).length;
			const totalVisibleRows = visibleProperties.length;

			return totalUnselectableRows === totalVisibleRows;
		},

		WS_DATATABLE_INDEX() {
			return WS_DATATABLE_INDEX;
		},

		WS_DATATABLE_SELECT() {
			return WS_DATATABLE_SELECT;
		},

		totalColumns() {
			if (this.expandableRows) {
				return this.columnObjects.length + 1;
			}

			return this.columnObjects.length;
		},

		isDataFiltered() {
			return (
				(this.searchTerm && this.searchTerm?.trim() !== "") ||
				Object.keys(this.appliedFilters).length > 0
			);
		}
	}
};
</script>

<style lang="scss" scoped>
$table-group-title-background-color: $color-grey-100;
$table-group-title-hover-background-color: $color-grey-200;

// .ws-data-table {
// 	overflow: hidden;
// }

.table-container {
	padding-bottom: 150px;
	overflow-y: hidden;
	// overflow-y: visible;
}

.is-clickable {
	cursor: pointer;
}

td.expand-toggle-button {
	width: 1px;
}
tr.row-selected {
	background-color: $color-primary-50 !important;

	&:hover {
		background-color: $color-primary-100 !important;
	}
}

.is-right {
	.field.is-grouped {
		justify-content: flex-end;
	}
}

.tr-group-title {
	background: $table-group-title-background-color;
	font-size: $size-6;
	font-weight: $weight-bold;
	cursor: pointer;

	&:hover {
		background: $table-group-title-hover-background-color !important;
	}

	td {
		border-bottom-width: 2px !important;
		height: 27px;
	}
}
</style>
