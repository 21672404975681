<template>
	<div
		class="is-flex is-justify-content-space-between is-align-items-center side-card-header"
	>
		<div class="card-title">
			<slot />
		</div>
		<ws-button
			is-subtle
			aria-label="close"
			@click="close"
			v-if="props.showCloseHeader"
			data-testid="side-card-close-btn"
			:tooltip="props.closeTooltip || $t('close')"
			class="mr-4"
		>
			<template #iconSmall>
				<ws-icon icon="close" />
			</template>
		</ws-button>
	</div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();
const props = defineProps({
	showCloseHeader: {
		type: Boolean,
		default: true
	},
	closeTooltip: {
		type: String,
		default: ""
	}
});

const emits = defineEmits(["close"]);
function close() {
	emits("close");
}
</script>
<style lang="scss" scoped>
.side-card-header {
	border-bottom: 1px solid $color-grey-300;
	height: 3.5rem;
}
.card-title {
	font-size: 1.25rem;
	font-weight: 600;
	padding: 0.5rem;
}
</style>
